import {usePoolConfigContext} from "../contexts/pools";
import {PoolTokenWeight} from "../components/SelectPoolTokenWeights";
import {Dispatch, SetStateAction} from "react";

export function usePoolConfigState() {
    const context = usePoolConfigContext();

    return {
        poolTokens: context.poolTokens as PoolTokenWeight[],
        setPoolTokens: context.setPoolTokens as Dispatch<SetStateAction<PoolTokenWeight[]>>,
        selectedKey: context.selectedKey as string,
        setSelectedKey: context.setSelectedKey as Dispatch<SetStateAction<string>>,
        fee: context.fee as number,
        setFee: context.setFee as Dispatch<SetStateAction<number>>,
    };
}