import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, InputNumber, Row, Table} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {TokenDisplay} from "../TokenDisplay";
import {useUserAccounts} from "../../hooks";
import {DEVNET_MINTS_TO_MAIN, MAINNET_MINTS_TO_DEV, ONE_PERCENT_BASIS} from "../../constants/finance";
import {displayU64TokenAmount, displayU64TokenBalance, numberToU64TokenAmount} from "../../utils/utils";
import {useAnchorWallet, useWallet} from "@solana/wallet-adapter-react";
import {depositAll} from "../../actions/millionfi";
import {useConnection} from "../../contexts/connection";
import {useHistory} from "react-router-dom";
import {PoolAccount} from "../../models";
import {cloneDeep} from "lodash";
import {notify} from "../../utils/notifications";

export interface DepositTokenRow {
    key: number;
    mint: string;
    weight: number;
    amount: number;
}

export const DepositPool = (props: { pool: PoolAccount, setPool: Dispatch<SetStateAction<PoolAccount | undefined>>, onSubmit: () => void }) => {
    const connection = useConnection();
    const anchorWallet = useAnchorWallet();
    const { wallet } = useWallet();
    const { connected } = useWallet();
    const { pool, setPool, onSubmit } = props;
    const { userAccounts } = useUserAccounts();

    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState<"info" | "success" | "warning" | "error" | undefined>("info");
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const history = useHistory();

    const [depositTokenRows, setDepositTokenRows] = useState<DepositTokenRow[]>([]);

    useEffect(() => {
        if (!pool) {
            return;
        }
        let rows = [];

        for (let i = 0; i < pool.info.numMints; i++) {
            const mint = pool.info.mints[i];
            const weight = pool.info.weightsNum[i];

            const mainMint = DEVNET_MINTS_TO_MAIN.get(mint.toBase58());

            const o = {
                key: i,
                mint: mainMint || mint.toBase58(),
                weight,
                amount: pool.depositAmounts ? parseFloat(displayU64TokenAmount(pool.depositAmounts[i])) : 0,
            };

            rows.push(o);
        }

        setDepositTokenRows(rows);
    }, [pool]);


    const errorMessage = useMemo(() => {
        return message === "" ? <></>:
            <Alert
                message={message}
                type={messageType}
                style={{margin: "auto", marginTop: 10}}
                showIcon />;
    }, [message, messageType]);

    const goBack = () => {
        history.push('/');
    }

    // Type in one input to determine token amounts for all tokens automatically (deposit all tokens evenly)
    const setTokenAmount = useCallback((key: number, amount: number) => {
        if (amount === null) {
            return;
        }

        let newPool: PoolAccount = cloneDeep(pool);
        newPool.depositAmounts[key] = numberToU64TokenAmount(amount);

        // Find ratio to use to get other token amounts
        const currTokenAmount = numberToU64TokenAmount(amount);
        const currTokenTotal = newPool.info.balances[key];

        for (let i = 0; i < newPool.info.numMints; ++i) {
            newPool.depositAmounts[i] = newPool.info.balances[i].mul(currTokenAmount).div(currTokenTotal);
        }

        newPool.lpRequested = newPool.info.lpBalance.mul(currTokenAmount).div(currTokenTotal);

        setPool(newPool);
    }, [pool, setPool]);

    // Get user's balance of a token
    const getTokenBalance = useCallback((mint: string) => {
        let mappedMint = MAINNET_MINTS_TO_DEV.get(mint);
        if (!mappedMint) {
            mappedMint = mint;
        }

        const index = userAccounts.findIndex(
            (acc) => acc.info.mint.toBase58() === mappedMint
        );

        if (index !== -1) {
            return parseFloat(displayU64TokenBalance(userAccounts[index]));
        }

        return 0;
    }, [userAccounts]);

    // Determine any error messages
    useEffect(() => {
        if (!connected) {
            setMessage("Sign in to your wallet to proceed.");
            setMessageType("info");
            return;
        }

        let isGreaterThanBalance = false;
        let isZero = false;

        for (let i = 0; i < pool.info.numMints; i++) {
            const mint = pool.info.mints[i];
            const bal = getTokenBalance(mint.toBase58());

            if (parseFloat(displayU64TokenAmount(pool.depositAmounts[i])) > bal) {
                isGreaterThanBalance = true;
            }

            if (pool.depositAmounts[i].isZero()) {
                isZero = true;
            }
        }

        if (isGreaterThanBalance) {
            setMessage("Amount cannot exceed your wallet balance.");
            setMessageType("error");
        } else if (isZero) {
            setMessage("Amount set cannot be zero.");
            setMessageType("error");
        } else {
            setMessage("");
            setMessageType("info");
        }

    }, [connected, pool, getTokenBalance]);


    const columns = useMemo(() => [
        {
            title: 'Token',
            dataIndex: 'mint',
            key: 'token',
            render: (mint: string, obj: any) => {
                const weight = obj["weight"] / ONE_PERCENT_BASIS;
                return <TokenDisplay weight={weight} mintAddress={mint} showBalance={true}/>
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number, obj: any) => {
                // Set red border if amount is invalid
                let inputClass = "";
                const balance = getTokenBalance(obj["mint"]);
                if (amount > balance || amount === 0) {
                    inputClass = "invalid-input-border";
                }

                return (
                    <>
                        <InputNumber
                            onFocus={(e) => e.target.select()}
                            className={inputClass}
                            defaultValue={amount}
                            value={amount}
                            size={"large"}
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            style={{display: "block", width: "100%", fontFamily: "Readex Pro Medium"}}
                            onChange={(num) => {
                                setTokenAmount(obj["key"], num);
                            }}
                        />
                        <div style={{display: "block", marginTop: 5}}>
                            <h3 style={{color: "#8657FD", cursor: "pointer"}} onClick={() => setTokenAmount(obj["key"], getTokenBalance(obj["mint"]))}>
                                Max
                            </h3>
                        </div>
                    </>
                );
            },
        },
    ], [getTokenBalance, setTokenAmount]);

    const handleDeposit = useCallback(async () => {
        if (anchorWallet && wallet) {
            setButtonEnabled(false);
            try {
                await depositAll(connection, anchorWallet, wallet.adapter, pool, userAccounts);
                onSubmit();
            } catch (e) {
                notify({ message: "Failed transaction.", type: "error" });
            }
            setButtonEnabled(true);
        }
    }, [anchorWallet, wallet, connection, pool, userAccounts, onSubmit]);

    const panel = (
        <div>
            <LeftOutlined
                className={"arrow-select"}
                style={{marginRight: 5, verticalAlign: "text-top", fontWeight: "Readex Pro Bold"}}
                onClick={goBack}
            />
            <h1 style={{display: "inline"}}>Deposit Liquidity</h1>
            <Table style={{marginTop: 10}} columns={columns} dataSource={depositTokenRows} size={"large"} pagination={false}/>

            <Row gutter={[16, 16]}>
                {errorMessage}
                <Col span={24}>
                    <Button
                        disabled={!connected || !!message || !buttonEnabled}
                        className={"continue-button-gradient"}
                        size="large"
                        onClick={handleDeposit}
                    >
                        Supply
                    </Button>
                </Col>
            </Row>
        </div>
    );

    return panel;
};
