import { Program, Provider } from '@project-serum/anchor';
import { Millionfi, IDL } from "../models/idl";
import idl from '../models/idl.json';
import {AnchorWallet} from "@solana/wallet-adapter-react";
import {
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction
} from "@solana/web3.js";
import { Buffer } from 'buffer';
import {TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {handleTxId, sendTransaction} from "../contexts/connection";
import {
  FEE_DENOMINATOR, ID_OSET,
  IS_FINALIZED_OSET,
  MAINNET_MINTS_TO_DEV, MAX_LP_TOKENS,
  POOL_ACC_SIZE, PROGRAM_VERSION, VERSION_OSET
} from "../constants/finance";
import {PoolTokenWeight} from "../components/SelectPoolTokenWeights";
import {WalletAdapter} from "@solana/wallet-adapter-base";
import {deserializePoolAccount, PoolAccount, TokenAccount} from "../models";
import {normalizeFeePercent, numberToU64TokenAmount, weightToBasis} from "../utils/utils";
import bs58 from "bs58";
import BN from "bn.js";
import {notify} from "../utils/notifications";

export const MILLION_FINANCE_PROGRAM_ID = new PublicKey(idl.metadata.address);

export function getProvider(connection: Connection, wallet: AnchorWallet) {
  return new Provider(connection, wallet, {preflightCommitment: "recent"});
}

export async function getAllPools(connection: Connection) {
  const rawPools = await connection.getProgramAccounts(MILLION_FINANCE_PROGRAM_ID, {
    filters: [
      {
        dataSize: POOL_ACC_SIZE,
      },
      {
        memcmp: {
          offset: IS_FINALIZED_OSET,
          bytes: bs58.encode(new Uint8Array([1])),
        }
      },
      {
        memcmp: {
          offset: VERSION_OSET,
          bytes: bs58.encode(new Uint8Array([PROGRAM_VERSION])),
        }
      }
    ],
  });

  return rawPools
      .map(({account, pubkey}) => deserializePoolAccount(pubkey, account))
      .sort((a, b) => {
        if (a.info.createdAt === b.info.createdAt) {
          return 0;
        } else if (a.info.createdAt > b.info.createdAt) {
          return -1
        }
        return 1;
      });
}

export async function getPool(connection: Connection, poolId: string) {
  const pools = await connection.getProgramAccounts(MILLION_FINANCE_PROGRAM_ID, {
    filters: [
      {
        dataSize: POOL_ACC_SIZE,
      },
      {
        memcmp: {
          offset: IS_FINALIZED_OSET,
          bytes: bs58.encode(new Uint8Array([1])),
        }
      },
      {
        memcmp: {
          offset: VERSION_OSET,
          bytes: bs58.encode(new Uint8Array([PROGRAM_VERSION])),
        }
      },
      {
        memcmp: {
          offset: ID_OSET,
          bytes: poolId,
        }
      }
    ]
  });

  return pools.length == 0 ? null : deserializePoolAccount(pools[0].pubkey, pools[0].account);
}

export async function swapTokenIn(
    connection: Connection, anchorWallet: AnchorWallet, wallet: WalletAdapter, poolAccount: PoolAccount, userAccounts: TokenAccount[],
    tokenMintIn: PublicKey, tokenMintOut: PublicKey, tokenAmountIn: BN, minTokenAmountOut: BN, maxSpotPriceNumer: BN, maxSpotPriceDenom: BN
) {
  const provider = getProvider(connection, anchorWallet);
  const program = new Program<Millionfi>(IDL, MILLION_FINANCE_PROGRAM_ID, provider);
  const poolId = poolAccount.info.id;

  const [poolPda, poolPdaBump] = await PublicKey.findProgramAddress([poolId.toBuffer()], MILLION_FINANCE_PROGRAM_ID);

  // TODO: replace token accounts with associated token accounts
  const [userTokenAccOutPda, userTokenAccOutBump] = await PublicKey.findProgramAddress([tokenMintOut.toBuffer(), anchorWallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID);

  const userTokenAccIn = userAccounts.find((acc) => acc.info.mint.equals(tokenMintIn));
  if (!userTokenAccIn) {
    notify({
      message: "User does not own token to swap in.",
      type: "error",
    });
    return;
  }

  const poolMintOutIndex = poolAccount.info.mints.findIndex((mintKey) => mintKey.equals(tokenMintOut));
  if (poolMintOutIndex === -1) {
    notify({
      message: "Invalid pool to swap, no token out exists.",
      type: "error",
    });
    return;
  }

  const poolTokenAccOut = poolAccount.info.tokenAccounts[poolMintOutIndex];

  const poolMintInIndex = poolAccount.info.mints.findIndex((mintKey) => mintKey.equals(tokenMintIn));
  if (poolMintInIndex === -1) {
    notify({
      message: "Invalid pool to swap, no token in exists.",
      type: "error",
    });
    return;
  }

  const poolTokenAccIn = poolAccount.info.tokenAccounts[poolMintInIndex];

  const ixs: TransactionInstruction[] = [];

  // TODO: consider increasing compute budget
  // const additionalComputeIx = new TransactionInstruction({
  //   keys: [],
  //   programId: new PublicKey("ComputeBudget111111111111111111111111111111"),
  //   data: Buffer.from(new BN(256000).toArray("le", 4)),
  // });
  // ixs.push(additionalComputeIx);

  const ix = program.instruction.swapTokenIn(
      poolId, poolPdaBump, userTokenAccOutBump, tokenAmountIn, minTokenAmountOut, maxSpotPriceNumer, maxSpotPriceDenom,
      {
        accounts: {
          authority: anchorWallet.publicKey,
          mpoolAcc: poolPda,
          userTokenAccIn: userTokenAccIn.pubkey,
          poolTokenAccIn: poolTokenAccIn,
          tokenMintOut: tokenMintOut,
          userTokenAccOut: userTokenAccOutPda,
          poolTokenAccOut: poolTokenAccOut,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        }
      }
  );
  ixs.push(ix);

  await sendTransaction(connection, wallet, ixs, [], true, "Swap successful.", "Failed swap.");
}

// TODO: Refactor merge with deposit function
export async function withdrawAll(connection: Connection, anchorWallet: AnchorWallet, wallet: WalletAdapter, poolAccount: PoolAccount, userAccounts: TokenAccount[]) {
  const provider = getProvider(connection, anchorWallet);
  const program = new Program<Millionfi>(IDL, MILLION_FINANCE_PROGRAM_ID, provider);
  const poolId = poolAccount.info.id;

  const [poolPda, poolPdaBump] = await PublicKey.findProgramAddress([poolId.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [lpMintPda, lpMintBump] = await PublicKey.findProgramAddress([Buffer.from("mpool_mint"), poolId.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [authorityLpMintAccPda, authorityLpMintAccBump] = await PublicKey.findProgramAddress(
      [lpMintPda.toBuffer(), anchorWallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID
  );

  let userTokenAccounts: PublicKey[] = [];
  let poolTokenAccounts: PublicKey[] = [];

  // Set the unused accounts to dummy accounts
  for (let i = 0; i < poolAccount.info.tokenAccounts.length; i++) {
    const poolTokenAccount = poolAccount.info.tokenAccounts[i];
    if (i < poolAccount.info.numMints) {
      poolTokenAccounts.push(poolTokenAccount);

      // Find user's token account too, has to exist or fail function
      const accIdx = userAccounts.findIndex((userAcc) => userAcc.info.mint.toBase58() === poolAccount.info.mints[i].toBase58());
      if (accIdx === -1) {
        console.error("user doesn't have token account to deposit");
        return;
      }

      userTokenAccounts.push(userAccounts[accIdx].pubkey);
    } else {
      poolTokenAccounts.push(poolAccount.info.tokenAccounts[0]);
      userTokenAccounts.push(userTokenAccounts[0]);
    }
  }

  if (userTokenAccounts.length != MAX_LP_TOKENS || poolTokenAccounts.length != MAX_LP_TOKENS) {
    console.error("invalid token account input len");
    return;
  }

  const ixs: TransactionInstruction[] = [];
  const withdrawAllIx = program.instruction.withdrawPool(
      poolId, poolPdaBump, lpMintBump, authorityLpMintAccBump, poolAccount.withdrawAmounts.slice(0, poolAccount.info.numMints), poolAccount.lpWithdrawn, poolAccount.info.numMints,
      {
        accounts: {
          authority: anchorWallet.publicKey,
          authorityLpMintAcc: authorityLpMintAccPda,
          mpoolAcc: poolPda,
          lpMint: lpMintPda,
          userTokenA: userTokenAccounts[0],
          userTokenB: userTokenAccounts[1],
          userTokenC: userTokenAccounts[2],
          userTokenD: userTokenAccounts[3],
          userTokenE: userTokenAccounts[4],
          poolTokenA: poolTokenAccounts[0],
          poolTokenB: poolTokenAccounts[1],
          poolTokenC: poolTokenAccounts[2],
          poolTokenD: poolTokenAccounts[3],
          poolTokenE: poolTokenAccounts[4],
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        }
      }
  );
  ixs.push(withdrawAllIx);

  await sendTransaction(connection, wallet, ixs, [], true, "Withdrew from pool.", "Failed to withdraw pool.");
}

// TODO: Refactor merge with withdraw function
export async function depositAll(connection: Connection, anchorWallet: AnchorWallet, wallet: WalletAdapter, poolAccount: PoolAccount, userAccounts: TokenAccount[]) {
  const provider = getProvider(connection, anchorWallet);
  const program = new Program<Millionfi>(IDL, MILLION_FINANCE_PROGRAM_ID, provider);
  const poolId = poolAccount.info.id;

  const [poolPda, poolPdaBump] = await PublicKey.findProgramAddress([poolId.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [lpMintPda, lpMintBump] = await PublicKey.findProgramAddress([Buffer.from("mpool_mint"), poolId.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [authorityLpMintAccPda, authorityLpMintAccBump] = await PublicKey.findProgramAddress(
      [lpMintPda.toBuffer(), anchorWallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID
  );

  let userTokenAccounts: PublicKey[] = [];
  let poolTokenAccounts: PublicKey[] = [];

  // Set the unused accounts to dummy accounts
  for (let i = 0; i < poolAccount.info.tokenAccounts.length; i++) {
    const poolTokenAccount = poolAccount.info.tokenAccounts[i];
    if (i < poolAccount.info.numMints) {
      poolTokenAccounts.push(poolTokenAccount);

      // Find user's token account too, has to exist or fail function
      const accIdx = userAccounts.findIndex((userAcc) => userAcc.info.mint.toBase58() === poolAccount.info.mints[i].toBase58());
      if (accIdx === -1) {
        console.error("user doesn't have token account to deposit");
        return;
      }

      userTokenAccounts.push(userAccounts[accIdx].pubkey);
    } else {
      poolTokenAccounts.push(poolAccount.info.tokenAccounts[0]);
      userTokenAccounts.push(userTokenAccounts[0]);
    }
  }

  if (userTokenAccounts.length != MAX_LP_TOKENS || poolTokenAccounts.length != MAX_LP_TOKENS) {
    console.error("invalid token account input len");
    return;
  }

  const ixs: TransactionInstruction[] = [];
  const depositAllIx = program.instruction.depositPool(
      poolId, poolPdaBump, lpMintBump, authorityLpMintAccBump, poolAccount.depositAmounts.slice(0, poolAccount.info.numMints), poolAccount.lpRequested,
      {
        accounts: {
          authority: anchorWallet.publicKey,
          authorityLpMintAcc: authorityLpMintAccPda,
          mpoolAcc: poolPda,
          lpMint: lpMintPda,
          userTokenA: userTokenAccounts[0],
          userTokenB: userTokenAccounts[1],
          userTokenC: userTokenAccounts[2],
          userTokenD: userTokenAccounts[3],
          userTokenE: userTokenAccounts[4],
          poolTokenA: poolTokenAccounts[0],
          poolTokenB: poolTokenAccounts[1],
          poolTokenC: poolTokenAccounts[2],
          poolTokenD: poolTokenAccounts[3],
          poolTokenE: poolTokenAccounts[4],
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        }
      }
  );
  ixs.push(depositAllIx);
  await sendTransaction(connection, wallet, ixs, [], true, "Deposited to pool.", "Failed to deposit pool.");
}

export async function createPoolTxn(
    connection: Connection, anchorWallet: AnchorWallet, wallet: WalletAdapter, numTokens: number, swapFee: number, poolTokens: PoolTokenWeight[], userAccounts: TokenAccount[]) {
  const provider = getProvider(connection, anchorWallet);
  const program = new Program<Millionfi>(IDL, MILLION_FINANCE_PROGRAM_ID, provider);

  const newPoolSeed = Keypair.generate().publicKey;
  const [poolPda, poolBump] = await PublicKey.findProgramAddress(
      [newPoolSeed.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [lpMintPda, lpMintBump] = await PublicKey.findProgramAddress(
      [Buffer.from("mpool_mint"), newPoolSeed.toBuffer()], MILLION_FINANCE_PROGRAM_ID);

  const normalizedSwapFee = normalizeFeePercent(swapFee);
  console.log(swapFee, normalizedSwapFee);
  const ixs: TransactionInstruction[] = [];

  const createPoolIx = program.instruction.createPool(
      newPoolSeed, poolBump, lpMintBump, numTokens,
      normalizedSwapFee, FEE_DENOMINATOR, 0, FEE_DENOMINATOR, 0, FEE_DENOMINATOR,
      {
        accounts: {
          authority: anchorWallet.publicKey,
          mpoolAcc: poolPda,
          lpMint: lpMintPda,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        }
      });

  ixs.push(createPoolIx);
  await sendTransaction(connection, wallet, ixs, [], true, "Initialized pool.", "Failed to initialize pool.");

  const addTokenIxs: TransactionInstruction[] = [];

  for (let i = 0; i < poolTokens.length; i++) {
    const poolToken = poolTokens[i];
    let mintKey = new PublicKey(poolToken.mint);

    let mappedMint = MAINNET_MINTS_TO_DEV.get(poolToken.mint);
    if (mappedMint) {
      mintKey = new PublicKey(mappedMint);
    } else {
      throw new Error("failed to map mainnet mint to devnet");
    }

    // Find user token account for mint
    const userTokenAccount = userAccounts.find((tokenAccount) => tokenAccount.info.mint.toBase58() === mintKey.toBase58());
    if (!userTokenAccount) {
      throw new Error("unable to find user token account");
    }

    const tokenAmount = numberToU64TokenAmount(poolToken.amount);
    const [poolTokenAccPda, poolTokenAccBump] = await PublicKey.findProgramAddress(
        [newPoolSeed.toBuffer(), mintKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID);

    const addTokenIx = program.instruction.addPoolToken(
        newPoolSeed, poolBump, poolTokenAccBump, weightToBasis(poolToken.weight), tokenAmount,
        {
          accounts: {
            authority: anchorWallet.publicKey,
            mpoolAcc: poolPda,
            tokenMint: mintKey,
            userTokenAcc: userTokenAccount.pubkey,
            poolTokenAcc: poolTokenAccPda,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY,
          }
        }
    );

    addTokenIxs.push(addTokenIx);
  }

  const [authorityLpMintAccPda, authorityLpMintAccBump] = await PublicKey.findProgramAddress(
      [lpMintPda.toBuffer(), anchorWallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID
  );

  addTokenIxs.push(program.instruction.finalizePool(
      newPoolSeed, poolBump, lpMintBump, authorityLpMintAccBump,
      {
        accounts: {
          authority: anchorWallet.publicKey,
          mpoolAcc: poolPda,
          lpMint: lpMintPda,
          authorityLpMintAcc: authorityLpMintAccPda,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        }
      })
  );

  await sendTransaction(connection, wallet, addTokenIxs, [], true, "Deposited tokens to new pool.", "Failed create pool.");
  return newPoolSeed;
}

export async function createTestTokens(connection: Connection, wallet: AnchorWallet) {
  const provider = getProvider(connection, wallet);
  const program = new Program<Millionfi>(IDL, MILLION_FINANCE_PROGRAM_ID, provider);

  const [testTokensAccount, testTokensAccountBump] = await PublicKey.findProgramAddress([Buffer.from("test2")], MILLION_FINANCE_PROGRAM_ID);
  const [usdcMint, usdcMintBump] = await PublicKey.findProgramAddress([Buffer.from("usdc_mint2")], MILLION_FINANCE_PROGRAM_ID);
  const [btcMint, btcMintBump] = await PublicKey.findProgramAddress([Buffer.from("btc_mint2")], MILLION_FINANCE_PROGRAM_ID);
  const [ethMint, ethMintBump] = await PublicKey.findProgramAddress([Buffer.from("eth_mint2")], MILLION_FINANCE_PROGRAM_ID);
  const [slndMint, slndMintBump] = await PublicKey.findProgramAddress([Buffer.from("slnd_mint2")], MILLION_FINANCE_PROGRAM_ID);
  const [srmMint, srmMintBump] = await PublicKey.findProgramAddress([Buffer.from("srm_mint2")], MILLION_FINANCE_PROGRAM_ID);

  const txid = await program.rpc.createTestTokens(testTokensAccountBump, usdcMintBump, btcMintBump, ethMintBump, slndMintBump, srmMintBump,
      {
        accounts: {
          authority: wallet.publicKey,
          testTokensAcc: testTokensAccount,
          usdc: usdcMint,
          btc: btcMint,
          eth: ethMint,
          slnd: slndMint,
          srm: srmMint,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        }
      });

  return await handleTxId(connection, txid, "Created test tokens", "Failed test tokens");
}

export async function mintTestTokens(connection: Connection, wallet: AnchorWallet) {
  const provider = getProvider(connection, wallet);
  const program = new Program<Millionfi>(IDL, MILLION_FINANCE_PROGRAM_ID, provider);

  const [testTokensAccount, testTokensAccountBump] = await PublicKey.findProgramAddress([Buffer.from("test2")], MILLION_FINANCE_PROGRAM_ID);
  const [usdcMint, usdcMintBump] = await PublicKey.findProgramAddress([Buffer.from("usdc_mint2")], MILLION_FINANCE_PROGRAM_ID);
  const [btcMint, btcMintBump] = await PublicKey.findProgramAddress([Buffer.from("btc_mint2")], MILLION_FINANCE_PROGRAM_ID);
  const [ethMint, ethMintBump] = await PublicKey.findProgramAddress([Buffer.from("eth_mint2")], MILLION_FINANCE_PROGRAM_ID);
  const [slndMint, slndMintBump] = await PublicKey.findProgramAddress([Buffer.from("slnd_mint2")], MILLION_FINANCE_PROGRAM_ID);
  const [srmMint, srmMintBump] = await PublicKey.findProgramAddress([Buffer.from("srm_mint2")], MILLION_FINANCE_PROGRAM_ID);

  // TODO: Replace with associated token addresses
  const [usdcAcc, usdcAccBump] = await PublicKey.findProgramAddress([usdcMint.toBuffer(), wallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [btcAcc, btcAccBump] = await PublicKey.findProgramAddress([btcMint.toBuffer(), wallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [ethAcc, ethAccBump] = await PublicKey.findProgramAddress([ethMint.toBuffer(), wallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [slndAcc, slndAccBump] = await PublicKey.findProgramAddress([slndMint.toBuffer(), wallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID);
  const [srmAcc, srmAccBump] = await PublicKey.findProgramAddress([srmMint.toBuffer(), wallet.publicKey.toBuffer()], MILLION_FINANCE_PROGRAM_ID);

  const txid = await program.rpc.mintTestTokens(
      testTokensAccountBump,
      usdcMintBump, btcMintBump, ethMintBump, slndMintBump, srmMintBump,
      usdcAccBump, btcAccBump, ethAccBump, slndAccBump, srmAccBump,
      {
        accounts: {
          authority: wallet.publicKey,
          testTokensAcc: testTokensAccount,
          usdc: usdcMint,
          btc: btcMint,
          eth: ethMint,
          slnd: slndMint,
          srm: srmMint,
          usdcAcc: usdcAcc,
          btcAcc: btcAcc,
          ethAcc: ethAcc,
          slndAcc: slndAcc,
          srmAcc: srmAcc,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        }
      });

  return await handleTxId(connection, txid, "Minted devnet test tokens", "Failed mint devnet test tokens");
}
