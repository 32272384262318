import React, {useState} from "react";
import {SelectPoolTokenWeights} from "../../components/SelectPoolTokenWeights";
import {SelectPoolFees} from "../../components/SelectPoolFees";
import {SelectInitialLiquidity} from "../../components/SelectInitialLiquidity";
import {Row} from "antd";

export declare type CreatePoolStep = "tokens" | "fees" | "liquidity";

export const CreateView = () => {
    const [createPoolStep, setCreatePoolStep] = useState<CreatePoolStep>("tokens");

    let panel: JSX.Element = <></>;
    switch (createPoolStep) {
        case "tokens":
            panel = <SelectPoolTokenWeights setCreatePoolStep={setCreatePoolStep}/>;
            break;
        case "fees":
            panel = <SelectPoolFees setCreatePoolStep={setCreatePoolStep} />
            break;
        case "liquidity":
            panel = <SelectInitialLiquidity setCreatePoolStep={setCreatePoolStep} />
            break;
    }

    return (
        <div className="flexColumn" style={{ flex: 1 }}>
          <div style={{marginTop: 40}}>
            <div style={{margin: "auto", padding: 7.5, maxWidth: 450, backgroundColor: "#0A0A0A", borderRadius: 20}} className="create-pool-panel">
              <h3 style={{color: "grey"}}>&#60;Solana Devnet&#62;</h3>
              {panel}
            </div>
          </div>
        </div>
    );
};
