import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, InputNumber, Row, Table} from "antd";
import {usePoolConfigState} from "../../hooks/usePoolConfigState";
import {CreatePoolStep} from "../../views/create/create";
import {LeftOutlined} from "@ant-design/icons";
import {TokenDisplay} from "../TokenDisplay";
import {PoolTokenWeight} from "../SelectPoolTokenWeights";
import {useUserAccounts} from "../../hooks";
import {MAINNET_MINTS_TO_DEV} from "../../constants/finance";
import {displayU64TokenBalance} from "../../utils/utils";
import {useAnchorWallet, useWallet} from "@solana/wallet-adapter-react";
import {createPoolTxn, depositAll} from "../../actions/millionfi";
import {useConnection} from "../../contexts/connection";
import {useHistory} from "react-router-dom";
import {notify} from "../../utils/notifications";

export interface SelectInitialLiquidityProps {
    setCreatePoolStep: Dispatch<SetStateAction<CreatePoolStep>>;
}

export const SelectInitialLiquidity = (props: SelectInitialLiquidityProps) => {
    const connection = useConnection();
    const anchorWallet = useAnchorWallet();
    const { wallet } = useWallet();
    const { connected } = useWallet();
    const { poolTokens, setPoolTokens, fee } = usePoolConfigState();
    const { userAccounts } = useUserAccounts();
    const { setCreatePoolStep } = props;

    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState<"info" | "success" | "warning" | "error" | undefined>("info");
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const history = useHistory();

    const errorMessage = useMemo(() => {
        return message === "" ? <></>:
                <Alert
                    message={message}
                    type={messageType}
                    style={{margin: "auto", marginTop: 10}}
                    showIcon />;
    }, [message, messageType]);

    const goBack = () => {
        setCreatePoolStep("fees");
    }

    const setTokenAmount = useCallback((key: string, amount: number) => {
        if (amount === null) {
            return;
        }

        let newTokens: PoolTokenWeight[] = JSON.parse(JSON.stringify(poolTokens));
        const target = newTokens.find((t) => t.key === key);
        if (target) {
            target.amount = amount;
            setPoolTokens(newTokens);
        }
    }, [poolTokens, setPoolTokens]);

    const getTokenBalance = useCallback((mint: string) => {
        let mappedMint = MAINNET_MINTS_TO_DEV.get(mint);
        if (!mappedMint) {
            return 0
        }

        const index = userAccounts.findIndex(
            (acc) => acc.info.mint.toBase58() === mappedMint
        );

        if (index !== -1) {
            return parseFloat(displayU64TokenBalance(userAccounts[index]));
        }

        return 0;
    }, [userAccounts]);

    // Determine any error messages
    useEffect(() => {
        if (!connected) {
            setMessage("Sign in to your wallet to proceed.");
            setMessageType("info");
            return;
        }

        let isGreaterThanBalance = false;
        let isZero = false;

        for (let i = 0; i < poolTokens.length; i++) {
            const poolToken = poolTokens[i];
            const bal = getTokenBalance(poolToken.mint);
            if (poolToken.amount > bal) {
                isGreaterThanBalance = true;
            } else if (poolToken.amount === 0) {
                isZero = true;
            }
        }
        if (isGreaterThanBalance) {
            setMessage("Amount cannot exceed your wallet balance.");
            setMessageType("error");
        } else if (isZero) {
            setMessage("Amount set cannot be zero.");
            setMessageType("error");
        } else {
            setMessage("");
            setMessageType("info");
        }
    }, [connected, poolTokens, getTokenBalance]);

    const columns = useMemo(() => [
        {
            title: 'Token',
            dataIndex: 'mint',
            key: 'token',
            render: (text: string, obj: any) => {
                const weight = poolTokens.find((poolToken) => poolToken.key === obj["key"])?.weight;
                return <TokenDisplay weight={weight} mintAddress={text} showBalance={true}/>
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number, obj: any) => {
                // Set red border if amount is invalid
                let inputClass = "";
                const balance = getTokenBalance(obj["mint"]);
                if (amount > balance || amount === 0) {
                    inputClass = "invalid-input-border";
                }

                return (
                    <>
                        <InputNumber
                            onFocus={(e) => e.target.select()}
                            className={inputClass}
                            defaultValue={amount}
                            value={amount}
                            size={"large"}
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            style={{display: "block", width: "100%", fontFamily: "Readex Pro Medium"}}
                            onChange={(num) => {
                                setTokenAmount(obj["key"], num);
                            }}
                        />
                        <div
                            onClick={() => setTokenAmount(obj["key"], getTokenBalance(obj["mint"]))}
                            style={{display: "block", marginTop: 5, color: "#8657FD", cursor: "pointer", fontFamily: "Readex Pro Medium"}}
                        >
                            Max
                        </div>
                    </>
                );
            },
        },
    ], [getTokenBalance, poolTokens, setTokenAmount]);

    const handleCreatePool = useCallback(async () => {
        if (anchorWallet && wallet) {
            setButtonEnabled(false);
            try {
                const poolId = await createPoolTxn(connection, anchorWallet, wallet.adapter, poolTokens.length, fee, poolTokens, userAccounts);
                history.push('/deposit/' + poolId.toString());
            } catch (e) {
                notify({ message: "Failed transaction.", type: "error" });
                setButtonEnabled(true);
            }
        }
    }, [wallet, anchorWallet, connection, fee, poolTokens, userAccounts, history]);

    const panel = (
        <div>
            <LeftOutlined
                className={"arrow-select"}
                style={{marginRight: 5, verticalAlign: "text-top"}}
                onClick={goBack}
            />
            <h1 style={{display: "inline"}}>Deposit Initial Liquidity</h1>
            <Table style={{marginTop: 10}} columns={columns} dataSource={poolTokens} size={"large"} pagination={false}/>

            <Row gutter={[16, 16]}>
                {errorMessage}
                <Col span={24}>
                    <Button
                        disabled={!connected || !!message || !buttonEnabled}
                        className={"continue-button-gradient"}
                        size="large"
                        onClick={handleCreatePool}
                    >
                        Create Pool
                    </Button>
                </Col>
            </Row>
        </div>
    );

    return panel;
};
