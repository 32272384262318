import React, {useMemo} from "react";
import { useMint, useAccountByMint } from "../../contexts/accounts";
import { TokenIcon } from "../TokenIcon";
import {MAINNET_MINTS_TO_DEV} from "../../constants/finance";
import {useTokenName} from "../../hooks";
import {Button} from "antd";
import {displayU64TokenBalance, getTokenBalance} from "../../utils/utils";

export const TokenDisplay = (props: {
  className?: string;
  mintAddress: string;
  icon?: JSX.Element | null;
  showBalance?: boolean;
  weight?: number;
  hideIcon?: boolean;
  buttonSize?: 'small' | 'middle' | 'large' | undefined;
}) => {
  const { className, showBalance, mintAddress, icon, weight, hideIcon, buttonSize } = props;

  let mappedMint = MAINNET_MINTS_TO_DEV.get(mintAddress);
  if (!mappedMint) {
      mappedMint = mintAddress;
  }

  const tokenSymbol = useTokenName(mintAddress);
  const tokenMint = useMint(mappedMint);
  const tokenAccount = useAccountByMint(mappedMint);

  let balance: string = "Sign in to wallet.";

  if (showBalance) {
    if (tokenAccount && tokenMint) {
      balance = displayU64TokenBalance(tokenAccount);
    }
  }

  let weightDisplay = <></>;
  if (weight) {
      weightDisplay = <small style={{display: "inline", marginLeft: 7, color: "grey", verticalAlign: "text-top"}}>{weight}%</small>;
  }

  const tokenIcon = hideIcon ? null :
      <TokenIcon style={{
          marginTop: "-0.2rem",
          marginLeft: "-0.15rem",
          marginRight: "0.5rem",
      }} mintAddress={mintAddress} />;

  return (
    <div>
      <div
        title={mappedMint}
        key={mappedMint}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button className={className} disabled={true} size={buttonSize || "large"} style={{fontFamily: "Readex Pro Medium", backgroundColor: "#344154"}}>
          {icon || tokenIcon}
          {tokenSymbol}
          {weightDisplay}
        </Button>
      </div>
      {showBalance ? (
          <h3 style={{display: "block", marginTop: 5, color: "grey", fontFamily: "Readex Pro Medium"}}>{balance}</h3>
      ) : null}
    </div>
  );
};
