import React, {useContext, useState} from "react";
import {getRandomKey} from "../utils/utils";
import {PoolTokenWeight} from "../components/SelectPoolTokenWeights";
import {SWAP_FEE_BUTTON_2_KEY} from "../components/SelectPoolFees";

const PoolConfigContext = React.createContext<any>(null);

export function PoolConfigProvider({ children = null as any }) {
    const initTokens = [
        {
            first: true,
            key: getRandomKey(),
            token: '',
            mint: '',
            weight: 50,
            amount: 0,

            balance: 0,
            balanceU64: ""
        },
        {
            key: getRandomKey(),
            token: '',
            mint: '',
            weight: 50,
            amount: 0,

            balance: 0,
            balanceU64: "",
        },
    ];

    const [poolTokens, setPoolTokens] = useState<PoolTokenWeight[]>(initTokens);
    const [selectedKey, setSelectedKey] = useState(SWAP_FEE_BUTTON_2_KEY);
    // Set the swap fee
    const [fee, setFee] = useState(0.3);
    // const { userAccounts } = useUserAccounts();
    //
    // useEffect(() => {
    //     let newTokens: PoolTokenWeight[] = JSON.parse(JSON.stringify(poolTokens));
    //
    //     for (let i = 0; i < newTokens.length; i++) {
    //         let newToken = newTokens[i];
    //
    //         let mappedMint = MAINNET_MINTS_TO_DEV.get(newToken.mint);
    //         if (!mappedMint) {
    //             const index = userAccounts.findIndex(
    //                 (acc) => acc.info.mint.toBase58() === mappedMint
    //             );
    //
    //             if (index !== -1) {
    //                 newToken.balance = parseFloat(displayU64TokenBalance(userAccounts[index]));
    //                 newToken.balanceU64 = userAccounts[index].info.amount.toString();
    //             }
    //         }
    //     }
    //
    //     setPoolTokens(newTokens);
    // }, [userAccounts]);

    return (
        <PoolConfigContext.Provider
            value={{
                poolTokens,
                setPoolTokens,
                selectedKey,
                setSelectedKey,
                fee,
                setFee,
            }}
        >
            {children}
        </PoolConfigContext.Provider>
    );
}

export const usePoolConfigContext = () => {
    const context = useContext(PoolConfigContext);

    return context;
};