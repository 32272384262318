import { useLocalStorageState } from "./../utils/utils";
import {
  clusterApiUrl,
  Connection, Keypair, Transaction, TransactionInstruction,
} from "@solana/web3.js";
import React, {useContext, useEffect, useMemo, useState} from "react";
import { setProgramIds } from "../utils/ids";
import {ENV as ChainID, TokenInfo, TokenListProvider} from "@solana/spl-token-registry";
import {notify} from "../utils/notifications";
import {cache} from "./accounts";
import {WalletAdapter, WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {ExplorerLink} from "../components/ExplorerLink";

export type ENV =
  | "mainnet-beta"
  | "testnet"
  | "devnet"
  | "localnet";

export let ENDPOINTS = [
  // {
  //   name: "mainnet-beta" as ENV,
  //   endpoint: "https://solana-api.projectserum.com/",
  //   chainID: ChainID.MainnetBeta,
  // },
  // {
  //   name: "testnet" as ENV,
  //   endpoint: clusterApiUrl("testnet"),
  //   chainID: ChainID.Testnet,
  // },
  // {
  //   name: "devnet" as ENV,
  //   endpoint: clusterApiUrl("devnet"),
  //   chainID: ChainID.Devnet,
  // },
  {
    name: "devnet" as ENV,
    endpoint: "https://api.devnet.solana.com",
    chainID: ChainID.Devnet,
  },
  {
    name: "localnet" as ENV,
    endpoint: "http://127.0.0.1:8899",
    chainID: ChainID.Devnet,
  },
];

const DEFAULT = ENDPOINTS[0].endpoint;
const DEFAULT_SLIPPAGE = 0.25;

interface ConnectionConfig {
  connection: Connection;
  sendConnection: Connection;
  endpoint: string;
  slippage: number;
  setSlippage: (val: number) => void;
  env: ENV;
  setEndpoint: (val: string) => void;
  tokens: TokenInfo[];
  tokenMap: Map<string, TokenInfo>;
}

const ConnectionContext = React.createContext<ConnectionConfig>({
  endpoint: DEFAULT,
  setEndpoint: () => {},
  slippage: DEFAULT_SLIPPAGE,
  setSlippage: (val: number) => {},
  connection: new Connection(DEFAULT, "confirmed"),
  sendConnection: new Connection(DEFAULT, "confirmed"),
  env: ENDPOINTS[0].name,
  tokens: [],
  tokenMap: new Map<string, TokenInfo>(),
});

export function ConnectionProvider({ children = undefined as any }) {
  const [endpoint, setEndpoint] = useLocalStorageState(
    "connectionEndpts",
    ENDPOINTS[0].endpoint
  );

  const [slippage, setSlippage] = useLocalStorageState(
    "slippage",
    DEFAULT_SLIPPAGE.toString()
  );

  const connection = useMemo(() => new Connection(endpoint, "confirmed"), [
    endpoint,
  ]);
  const sendConnection = useMemo(() => new Connection(endpoint, "confirmed"), [
    endpoint,
  ]);

  const chain =
    ENDPOINTS.find((end) => end.endpoint === endpoint) || ENDPOINTS[0];
  const env = chain.name;

  const [tokens, setTokens] = useState<TokenInfo[]>([]);
  const [tokenMap, setTokenMap] = useState<Map<string, TokenInfo>>(new Map());

  useEffect(() => {
    cache.clear();
    // fetch token files
    (async () => {
      const res = await new TokenListProvider().resolve();
      const list = res
        // .filterByChainId(chain.chainID)
        .filterByChainId(ChainID.MainnetBeta)
        .excludeByTag("nft")
        .getList();
      const knownMints = list.reduce((map, item) => {
        map.set(item.address, item);
        return map;
      }, new Map<string, TokenInfo>());

      // const accounts = await getMultipleAccounts(connection, [...knownMints.keys()], 'single');
      // accounts.keys.forEach((key, index) => {
      //   const account = accounts.array[index];
      //   if(!account) {
      //     return;
      //   }
      //
      //   cache.addNft(new PublicKey(key), account, MintParser);
      // })

      setTokenMap(knownMints);
      setTokens(list);
    })();
  }, [connection, chain]);

  setProgramIds(env);

  // The websocket library solana/web3.js uses closes its websocket connection when the subscription list
  // is empty after opening its first time, preventing subsequent subscriptions from receiving responses.
  // This is a hack to prevent the list from ever getting empty
  useEffect(() => {
    const id = connection.onAccountChange(new Keypair().publicKey, () => {});
    return () => {
      connection.removeAccountChangeListener(id);
    };
  }, [connection]);

  useEffect(() => {
    const id = connection.onSlotChange(() => null);
    return () => {
      connection.removeSlotChangeListener(id);
    };
  }, [connection]);

  useEffect(() => {
    const id = sendConnection.onAccountChange(
      new Keypair().publicKey,
      () => {}
    );
    return () => {
      sendConnection.removeAccountChangeListener(id);
    };
  }, [sendConnection]);

  useEffect(() => {
    const id = sendConnection.onSlotChange(() => null);
    return () => {
      sendConnection.removeSlotChangeListener(id);
    };
  }, [sendConnection]);

  return (
    <ConnectionContext.Provider
      value={{
        endpoint,
        setEndpoint,
        slippage: parseFloat(slippage),
        setSlippage: (val) => setSlippage(val.toString()),
        connection,
        sendConnection,
        tokens,
        tokenMap,
        env,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
}

export function useConnection() {
  return useContext(ConnectionContext).connection as Connection;
}

export function useSendConnection() {
  return useContext(ConnectionContext)?.sendConnection;
}

export function useConnectionConfig() {
  const context = useContext(ConnectionContext);
  return {
    endpoint: context.endpoint,
    setEndpoint: context.setEndpoint,
    env: context.env,
    tokens: context.tokens,
    tokenMap: context.tokenMap,
  };
}

export async function handleTxId(connection: Connection, txid: string, success?: string, fail?: string) {
  let options = {
    skipPreflight: true,
    commitment: "singleGossip",
  };

  const status = (
    await connection.confirmTransaction(
      txid,
      options && (options.commitment as any)
    )
  ).value;

  if (status?.err) {
    console.error(JSON.stringify(status));
    notify({
      message: fail || "Transaction failed.",
      type: "error",
    });
  } else {
    notify({
      message: success || "Transaction success.",
      type: "success",
    });
  }
}

// export function useSlippageConfig() {
//   const { slippage, setSlippage } = useContext(ConnectionContext);
//   return { slippage, setSlippage };
// }

const getErrorForTransaction = async (connection: Connection, txid: string) => {
  // wait for all confirmation before getting transaction
  await connection.confirmTransaction(txid, "max");

  const tx = await connection.getParsedConfirmedTransaction(txid);

  const errors: string[] = [];
  if (tx?.meta && tx.meta.logMessages) {
    tx.meta.logMessages.forEach((log) => {
      const regex = /Error: (.*)/gm;
      let m;
      while ((m = regex.exec(log)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        if (m.length > 1) {
          errors.push(m[1]);
        }
      }
    });
  }

  return errors;
};

export const sendTransaction = async (
  connection: Connection,
  wallet: WalletAdapter,
  instructions: TransactionInstruction[],
  signers: Keypair[],
  awaitConfirmation = true,
  successMsg?: string,
  failMsg?: string
) => {
  if (!wallet?.publicKey) {
    throw new WalletNotConnectedError();
  }

  let transaction = new Transaction();
  instructions.forEach((instruction) => transaction.add(instruction));
  transaction.recentBlockhash = (
    await connection.getRecentBlockhash("max")
  ).blockhash;

  transaction.feePayer = wallet.publicKey;
  // transaction.setSigners(
  //   // fee paid by the wallet owner
  //   wallet.publicKey,
  //   ...signers.map((s) => s.publicKey)
  // );
  if (signers.length > 0) {
    transaction.partialSign(...signers);
  }

  const txid = await wallet.sendTransaction(transaction, connection);

  // transaction = await wallet.signTransaction(transaction);
  // const rawTransaction = transaction.serialize();
  // let options = {
  //   skipPreflight: true,
  //   commitment: "singleGossip",
  // };

  // const txid = await connection.sendRawTransaction(rawTransaction, options);

  if (awaitConfirmation) {
    await handleTxId(connection, txid, successMsg, failMsg);
  }

  return txid;
};
