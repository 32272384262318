import React, {useCallback, useMemo} from 'react';
import {List, Modal} from "antd";
import {MAINNET_MINTS_TO_DEV} from "../../constants/finance";
import {TokenIcon} from "../TokenIcon";
import {useConnectionConfig} from "../../contexts/connection";
import {useUserAccounts} from "../../hooks";
import {displayU64TokenBalance} from "../../utils/utils";
import {PublicKey} from "@solana/web3.js";

export interface TokenSelectorModalProps {
    isModalVisible: boolean;
    onSelect: (mint: string) => void;
    onClose: () => void;
    filteredMints?: PublicKey[] | string[];
}

export const TokenSelectorModal = (props: TokenSelectorModalProps) => {
    const { tokenMap } = useConnectionConfig();
    const { userAccounts } = useUserAccounts();
    const { isModalVisible, onSelect, onClose, filteredMints } = props;

    // Filter out tokens already used
    const availableTokenMints = useMemo(() => {
        const exclude = new Set<string>((filteredMints || []).map((mint) => typeof mint === "string" ? mint : mint.toBase58()).values());
        return Array.from(MAINNET_MINTS_TO_DEV.keys()).filter((mint) => !exclude.has(mint));;
    }, [filteredMints]);

    const renderTokenRow = useCallback((mint: string) => {
        const tokenInfo = tokenMap.get(mint);

        let mintSymbol = "N/A";
        let mintName = "N/A";
        let tokenBalance = "0";

        if (tokenInfo) {
            mintSymbol = tokenInfo.symbol;
            mintName = tokenInfo.name;

            // Find user's token balance
            let tokenAccIndex = userAccounts.findIndex((tokenAcc) => {
                return tokenAcc.info.mint.toBase58() === MAINNET_MINTS_TO_DEV.get(mint);
            });

            if (tokenAccIndex !== -1) {
                tokenBalance = displayU64TokenBalance(userAccounts[tokenAccIndex]);
            }
        }

        return (
            <List.Item className={"noselect"} style={{cursor: "pointer", padding: 8}} onClick={ () => {onSelect(mint) }}>
                <List.Item.Meta
                    avatar={<TokenIcon style={{marginLeft: 7, marginTop: 7}} size={34} mintAddress={mint} />}
                    title={mintSymbol}
                    description={mintName}
                />
                <div style={{marginRight: 10}}>{tokenBalance}</div>
            </List.Item>
        );
    }, [userAccounts, tokenMap, onSelect]);

    return <Modal
        title="Select a token"
        visible={isModalVisible}
        closable={true}
        maskClosable={true}
        onCancel={onClose}
        footer={null}
        keyboard={true}
        style={{fontFamily: "Readex Pro SemiBold"}}
        bodyStyle={{padding: 0}}
        width={450}
    >
        <List
            size="large"
            dataSource={availableTokenMints}
            renderItem={renderTokenRow}
        />
    </Modal>
}