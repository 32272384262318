
// TODO: replace devnet mints with v2 test tokens using new seed after creation
export const DEVNET_MINTS_TO_MAIN = new Map<string, string>([
  ["8HVUq1VmXe32mNN8uUDXruSufwmVj7ZnFmdbi2iSxkQj", "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"], // USDC
  ["EgZfjeZkNSzRmieJidhGC85Vk4vHgWbE8o518cBjTCfL", "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E"], // BTC
  ["CSo4ECrbkHTWbiAkzGhQJiumNnuWaZQFnhTw3NvDhEgB", "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs"], // ETH
  ["GxLs8vNRj6CL3VPiFhwCaBYmtMYLu9bxP7yzxbEAg1jH", "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt"], // SRM
  ["CwmPrPMf6epm69kwvsHvuAcoaco7v2dgfyTFa27Fu1nD", "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp"], // SLND
]);

export const MAINNET_MINTS_TO_DEV = new Map<string, string>([
  ["EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", "8HVUq1VmXe32mNN8uUDXruSufwmVj7ZnFmdbi2iSxkQj"], // USDC
  ["9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E", "EgZfjeZkNSzRmieJidhGC85Vk4vHgWbE8o518cBjTCfL"], // BTC
  ["7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs", "CSo4ECrbkHTWbiAkzGhQJiumNnuWaZQFnhTw3NvDhEgB"], // ETH
  ["SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt", "GxLs8vNRj6CL3VPiFhwCaBYmtMYLu9bxP7yzxbEAg1jH"], // SRM
  ["SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp", "CwmPrPMf6epm69kwvsHvuAcoaco7v2dgfyTFa27Fu1nD"], // SLND
]);

export const DEVNET_MINTS_TO_PRICE = new Map<string, number>([
  ["8HVUq1VmXe32mNN8uUDXruSufwmVj7ZnFmdbi2iSxkQj", 1], // USDC start devnet
  ["EgZfjeZkNSzRmieJidhGC85Vk4vHgWbE8o518cBjTCfL", 38419.73], // BTC
  ["CSo4ECrbkHTWbiAkzGhQJiumNnuWaZQFnhTw3NvDhEgB", 2545.28], // ETH
  ["GxLs8vNRj6CL3VPiFhwCaBYmtMYLu9bxP7yzxbEAg1jH", 1.81], // SRM
  ["CwmPrPMf6epm69kwvsHvuAcoaco7v2dgfyTFa27Fu1nD", 1.79], // SLND end devnet
  ["EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", 1], // USDC
  ["9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E", 38419.73], // BTC
  ["7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs", 2545.28], // ETH
  ["SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt", 1.81], // SRM
  ["SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp", 1.79], // SLND
]);

export const ADMIN_PUBLIC_KEY = "3ZGjV8DKbWo3aM4hGAzRoRYvpi3QAn3tXs6u6CFp8FvV";
export const MIN_LP_TOKENS = 2;
export const MAX_LP_TOKENS = 5;
export const DEVNET_TOKEN_DECIMALS = 9;
export const MIN_TOKEN_WEIGHT = 1;
export const MAX_TOKEN_WEIGHT = 99;
export const MAX_LP_WEIGHT = 10000;
export const ONE_PERCENT_BASIS = 100;
export const FEE_DENOMINATOR = 1000000;
export const MOBILE_WINDOW_WIDTH = 500;
export const POOL_ACC_SIZE = 556;

export const DEFAULT_BLUE = "#197DDC";
export const LABEL_SELECT_TOKEN = "Select Token";

// Offsets for MPoolAccount
export const IS_FINALIZED_OSET = 8 + 4 + 32 + 32 + 8 + 32;
export const VERSION_OSET = 8;
export const ID_OSET = 12;

export const PROGRAM_VERSION = 3;
