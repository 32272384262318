import React, {useEffect, useMemo, useState} from "react";
import {getPool} from "../../actions/millionfi";
import {useConnection} from "../../contexts/connection";
import {PoolAccount} from "../../models";
import {DepositPool} from "../../components/DepositPool";
import {MultiPoolIcon} from "../../components/TokenIcon";
import {Button, Col, Row} from "antd";
import {DEVNET_MINTS_TO_MAIN} from "../../constants/finance";
import {
    displayU64TokenAmount,
    formatNumber,
    getTokenBalance,
    getTVLDevnet,
    numberToU64TokenAmount
} from "../../utils/utils";
import {useHistory} from "react-router-dom";
import BN from "bn.js";
import {useUserAccounts} from "../../hooks";
import {Keypair, PublicKey} from "@solana/web3.js";

// TODO: Refactor merge this view with withdraw
export const DepositView = (props: { poolId: string, inputPool?: PoolAccount }) => {
    const history = useHistory();
    const connection = useConnection();
    const [pool, setPool] = useState<PoolAccount>();
    const { userAccounts } = useUserAccounts();
    const [yourShare, setYourShare] = useState(0);
    const [refreshPool, setRefreshPool] = useState<PublicKey>(Keypair.generate().publicKey);
    const { poolId, inputPool } = props;

    useEffect(() => {
        const fetchPool = async () => {
            if (inputPool) {
                setPool(inputPool);
                return;
            }

            const res = await getPool(connection, poolId);
            if (res) {
                setPool(res);
            }
        };

        fetchPool().catch(console.error);
    }, [connection, inputPool, poolId, refreshPool]);

    const tvl = useMemo(() => {
        if (!pool) return 0;
        return Math.floor(getTVLDevnet(pool.info.mints, pool.info.balances));
    }, [pool]);

    // Get user's share of pool tvl
    useEffect(() => {
        if (!pool || !userAccounts) {
            return;
        }
        const lpMint = pool.info.lpMint;
        const totalPoolLp = pool.info.lpBalance;
        const userLpBalance = numberToU64TokenAmount(getTokenBalance(lpMint.toBase58(), userAccounts));
        const userShareDollars = new BN(tvl).mul(userLpBalance).div(totalPoolLp).toNumber();

        setYourShare(userShareDollars);
    }, [pool, userAccounts, tvl]);

    return (
        <div className="flexColumn" style={{ flex: 1 }}>
            <div style={{marginTop: 20}}>
                <div style={{margin: "auto", maxWidth: 450, borderRadius: 20}} className="create-pool-panel">
                    {
                        pool ? (
                            <div style={{marginLeft: 10}}>
                                <Row gutter={[16, 16]} style={{marginBottom: 10}}>
                                    <Col span={24}>
                                        <MultiPoolIcon className={"multi-pool-icon"} mints={pool.info.mints.map((mint) => {
                                            return DEVNET_MINTS_TO_MAIN.get(mint.toBase58()) || mint.toBase58()
                                        }).slice(0, pool.info.numMints)} />
                                    </Col>
                                </Row>

                                <Row align={"bottom"} gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Row align={"bottom"} gutter={[16, 16]}>
                                            <Col span={24}>
                                                <h3 style={{ float: "left", marginBottom: -5, color: "grey"}}>Pool Value</h3>
                                            </Col>
                                        </Row>
                                        <Row align={"bottom"} gutter={[16, 16]}>
                                            <Col span={24}>
                                                <h2 style={{ float: "left"}}>${formatNumber.format(tvl)} </h2>
                                            </Col>
                                        </Row>
                                        <Row align={"bottom"} gutter={[16, 16]}>
                                            <Col span={24}>
                                                <h3 style={{ float: "left", marginBottom: -5, color: "grey"}}>Your Share</h3>
                                            </Col>
                                        </Row>
                                        <Row align={"bottom"} gutter={[16, 16]}>
                                            <Col span={24}>
                                                <h2 style={{ float: "left"}}>${formatNumber.format(yourShare)} </h2>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={12}>
                                        <Button size={"large"} onClick={() => history.push("/withdraw/" + poolId)} style={{border: "solid 1px", float: "right", marginRight: 20, marginBottom: 15, fontFamily: "Readex Pro Medium"}}>Withdraw</Button>
                                    </Col>
                                </Row>
                            </div>
                        ): <></>
                    }
                </div>
                <div style={{margin: "auto", padding: 7.5, maxWidth: 450, backgroundColor: "#0A0A0A", borderRadius: 20}} className="create-pool-panel">
                    <h3 style={{color: "grey"}}>{ pool ? `<Solana Devnet>` : `Loading... reload if needed`}</h3>
                    {
                        pool ? (
                            <DepositPool onSubmit={() => setRefreshPool(Keypair.generate().publicKey)} pool={pool} setPool={setPool} />
                        ): <></>
                    }
                </div>
            </div>
        </div>
    );
};
