import React, {useCallback, useMemo} from "react";
import { useConnection } from "../../contexts/connection";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { notify } from "../../utils/notifications";
import { ConnectButton } from "./../../components/ConnectButton";
import { LABELS } from "../../constants";
import {useAnchorWallet, useWallet} from "@solana/wallet-adapter-react";
import {createTestTokens, mintTestTokens} from "../../actions/millionfi";
import {ADMIN_PUBLIC_KEY} from "../../constants/finance";
import {Col, Row} from "antd";

export const FaucetView = () => {
  const connection = useConnection();
  const { publicKey } = useWallet();
  const anchorWallet = useAnchorWallet();

  const showCreateTestTokens = useMemo(() => {
    return publicKey && publicKey.toBase58() === ADMIN_PUBLIC_KEY;
  }, [publicKey])

  const handleRequestAirdrop = useCallback(async () => {
    try {
      if (!publicKey) {
        return;
      }
      const res = await connection.requestAirdrop(publicKey, 2 * LAMPORTS_PER_SOL);

      notify({
        message: LABELS.ACCOUNT_FUNDED,
        type: "success",
      });
    } catch (error) {
      notify({
        message: LABELS.AIRDROP_FAIL,
        type: "error",
      });
      console.error(error);
    }
  }, [publicKey, connection]);

  const handleCreateTestTokens = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    await createTestTokens(connection, anchorWallet);
  }, [connection, anchorWallet]);

  const handleMintTestTokens = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    await mintTestTokens(connection, anchorWallet);
  }, [connection, anchorWallet]);

  return (
    <div className="flexColumn" style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
      <div style={{margin: "auto", padding: 10, marginTop: 30, border: "solid 2px #0A0A0A", borderRadius: 20, backgroundColor: "#0A0A0A", maxWidth: 500}}>
        <h2 style={{display: "inline"}}>Devnet Test Guide</h2>
        <p>This demo app for decentralized index funds is implemented w/ an auto market maker that supports weighted multi-token pools.</p>
        <p>Users invest in indices by depositing liquidity into weighted pools and receive LP tokens that can be used to withdraw in the future.</p>
        <p>Indices are automatically rebalanced by traders who seek arbitrage opportunities. Each token swap generates fees for investors.</p>

        <div style={{ margin: 20 }}>
          <p><span style={{color: "#D91584"}}>1.)</span> Set your Solana wallet's network to Devnet instead of Mainnet.</p>
          <p><span style={{color: "#D91584"}}>2.)</span> Click 1st button to get SOL.</p>
          <p><span style={{color: "#D91584"}}>3.)</span> Click 2nd button to get 5 different tokens to use on the pools.</p>
          <p><span style={{color: "#D91584"}}>4.)</span> Go back and test creating pools, deposit, withdraw, & swap!</p>
          <p style={{color: "grey"}}>Note: Don't spam SOL airdrop (1st button) to avoid being temporarily blocked by Solana Devnet.</p>
        </div>

        <Row style={{marginBottom: 10}}>
          <Col span={24}>
            <ConnectButton type="primary" onClick={handleRequestAirdrop}>
              Mint SOL (Devnet)
            </ConnectButton>
          </Col>
          <Col span={24} style={{marginTop: 20}}>
            <ConnectButton type="primary" onClick={handleMintTestTokens}>
              Mint Test Tokens (Devnet)
            </ConnectButton>
          </Col>
          {
            showCreateTestTokens ?
                <Col span={24} style={{marginTop: 20}}>
                  <ConnectButton type="primary" onClick={handleCreateTestTokens}>
                    Create Test Tokens
                  </ConnectButton>
                </Col>
                 :
                <></>
          }
        </Row>


      </div>


    </div>
  );
};