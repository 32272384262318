import { useCallback, useState } from "react";
import {MintInfo, u64} from "@solana/spl-token";

import { TokenAccount } from "./../models";
import { PublicKey } from "@solana/web3.js";
import BN from "bn.js";
import { WAD, ZERO} from "../constants";
import { TokenInfo } from "@solana/spl-token-registry";
import {
  DEVNET_MINTS_TO_PRICE,
  DEVNET_TOKEN_DECIMALS,
  FEE_DENOMINATOR,
  MAINNET_MINTS_TO_DEV,
  ONE_PERCENT_BASIS
} from "../constants/finance";
import {Decimal} from 'decimal.js';

export type KnownTokenMap = Map<string, TokenInfo>;

export const formatPriceNumber = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 8,
});

export function useLocalStorageState(key: string, defaultState?: string) {
  const [state, setState] = useState(() => {
    // NOTE: Not sure if this is ok
    const storedState = localStorage.getItem(key);
    if (storedState) {
      return JSON.parse(storedState);
    }
    return defaultState;
  });

  const setLocalStorageState = useCallback(
    (newState) => {
      const changed = state !== newState;
      if (!changed) {
        return;
      }
      setState(newState);
      if (newState === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newState));
      }
    },
    [state, key]
  );

  return [state, setLocalStorageState];
}

// shorten the checksummed version of the input address to have 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
}

export function getTokenName(
  map: KnownTokenMap,
  mint?: string | PublicKey,
  shorten = true
): string {
  const mintAddress = typeof mint === "string" ? mint : mint?.toBase58();

  if (!mintAddress) {
    return "N/A";
  }

  const knownSymbol = map.get(mintAddress)?.symbol;
  if (knownSymbol) {
    return knownSymbol;
  }

  return shorten ? `${mintAddress.substring(0, 5)}...` : mintAddress;
}

export function getTokenByName(tokenMap: KnownTokenMap, name: string) {
  let token: TokenInfo | null = null;
  for (const val of tokenMap.values()) {
    if (val.symbol === name) {
      token = val;
      break;
    }
  }
  return token;
}

export function getTokenIcon(
  map: KnownTokenMap,
  mintAddress?: string | PublicKey
): string | undefined {
  const address =
    typeof mintAddress === "string" ? mintAddress : mintAddress?.toBase58();
  if (!address) {
    return;
  }

  return map.get(address)?.logoURI;
}

export function isKnownMint(map: KnownTokenMap, mintAddress: string) {
  return !!map.get(mintAddress);
}

export const STABLE_COINS = new Set(["USDC", "wUSDC", "USDT"]);

export function chunks<T>(array: T[], size: number): T[][] {
  return Array.apply<number, T[], T[][]>(
    0,
    new Array(Math.ceil(array.length / size))
  ).map((_, index) => array.slice(index * size, (index + 1) * size));
}

export function toLamports(
  account?: TokenAccount | number,
  mint?: MintInfo
): number {
  if (!account) {
    return 0;
  }

  const amount =
    typeof account === "number" ? account : account.info.amount?.toNumber();

  const precision = Math.pow(10, mint?.decimals || 0);
  return Math.floor(amount * precision);
}

export function wadToLamports(amount?: BN): BN {
  return amount?.div(WAD) || ZERO;
}

export function fromLamports(
  account?: TokenAccount | number | BN,
  mint?: MintInfo,
  rate: number = 1.0
): number {
  if (!account) {
    return 0;
  }

  const amount = Math.floor(
    typeof account === "number"
      ? account
      : BN.isBN(account)
      ? account.toNumber()
      : account.info.amount.toNumber()
  );

  const precision = Math.pow(10, mint?.decimals || 0);
  return (amount / precision) * rate;
}

var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

const abbreviateNumber = (number: number, precision: number) => {
  let tier = (Math.log10(number) / 3) | 0;
  let scaled = number;
  let suffix = SI_SYMBOL[tier];
  if (tier !== 0) {
    let scale = Math.pow(10, tier * 3);
    scaled = number / scale;
  }

  return scaled.toFixed(precision) + suffix;
};

export const formatAmount = (
  val: number,
  precision: number = 6,
  abbr: boolean = true
) => (abbr ? abbreviateNumber(val, precision) : val.toFixed(precision));

export function formatTokenAmount(
  account?: TokenAccount,
  mint?: MintInfo,
  rate: number = 1.0,
  prefix = "",
  suffix = "",
  precision = 6,
  abbr = false
): string {
  if (!account) {
    return "";
  }

  return `${[prefix]}${formatAmount(
    fromLamports(account, mint, rate),
    precision,
    abbr
  )}${suffix}`;
}

export const formatUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const isSmallNumber = (val: number) => {
  return val < 0.001 && val > 0;
};

export const formatNumber = {
  format: (val?: number, useSmall?: boolean) => {
    if (!val) {
      return "--";
    }
    if (useSmall && isSmallNumber(val)) {
      return 0.001;
    }

    return numberFormatter.format(val);
  },
};

export const feeFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 9,
});

export const formatPct = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function convert(
  account?: TokenAccount | number,
  mint?: MintInfo,
  rate: number = 1.0
): number {
  if (!account) {
    return 0;
  }

  const amount =
    typeof account === "number" ? account : account.info.amount?.toNumber();

  const precision = Math.pow(10, mint?.decimals || 0);
  let result = (amount / precision) * rate;

  return result;
}

export function bytesToString(bytes: number[]) {
  return String.fromCharCode(...bytes).replace(/\u0000/g, '');
}

export function bytesToNumber(bytes: number[]) {
  let value = 0;
  for (let i = bytes.length - 1; i >= 0; i--) {
    value = (value * 256) + bytes[i];
  }
  return value;
}

export function bytesToU64TokenAmount(bytes: number[]) {
  if (bytes.length != 8) {
    console.error("invalid u64 token amount input");
  }

  let value = new BN(0);

  for (let i = bytes.length - 1; i >= 0; i--) {
    value = (value.mul(new BN(256))).add(new BN(bytes[i]));
  }

  return value;
}

export function bytesToU32Array(bytes: number[]) {
  if (bytes.length % 4 !== 0) {
    console.error("invalid input array of u32 parts");
  }

  let res: number[] = [];

  for (let i = 0; i < bytes.length; i += 4) {
    res.push(bytesToNumber(bytes.slice(i, i+4)));
  }

  return res;
}

export function bytesToU64TokenAmountArray(bytes: number[]) {
  if (bytes.length % 8 !== 0) {
    console.error("invalid input array of u64 token amount parts");
  }

  let res: BN[] = [];

  for (let i = 0; i < bytes.length; i += 8) {
    res.push(bytesToU64TokenAmount(bytes.slice(i, i+8)));
  }

  return res;
}

export function bytesToPubkeyArray(bytes: number[]) {
  if (bytes.length % 32 !== 0) {
    console.error("invalid input array of 32 byte parts");
  }

  let res: PublicKey[] = [];

  for (let i = 0; i < bytes.length; i += 32) {
    res.push(new PublicKey(bytes.slice(i, i+32)));
  }

  return res;
}

export function getRandomKey() {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
}

export function getRandomU32() {
  return Math.floor(Math.random() * 4294967295);
}

export function normalizeFeePercent(f: number) {
  return f / 100 * FEE_DENOMINATOR;
}

export function displayPercent(bps: number) {
  return bps / ONE_PERCENT_BASIS;
}

// Compatible with devnet tokens
export function displayU64TokenBalance(acc: TokenAccount) {
  return u64ToString(acc.info.amount, DEVNET_TOKEN_DECIMALS);
}

export function displayU64TokenAmount(amount: BN) {
  return u64ToString(amount, DEVNET_TOKEN_DECIMALS);
}

export function numberToU64TokenAmount(num: number) {
  if (num === null) {
    return new BN(0);
  }

  const decimalsBN = new BN(DEVNET_TOKEN_DECIMALS);
  const multiplier = new BN(10).pow(decimalsBN);

  const bnNum = new BN(num);
  let strNum = num.toString();
  const parts = strNum.split(".");

  let res = bnNum.mul(multiplier);

  if (parts.length !== 2 || trimTrailingZeros(parts[1]).length === 0) {
    return res;
  }

  const dec = trimTrailingZeros(parts[1]);
  const decDivisor = new BN(10).pow(new BN(dec.length));
  const decU64 = (new BN(dec)).mul(multiplier).div(decDivisor);
  return res.add(decU64);
}

export function u64ToString(amount: u64, decimals: number) {
  const decimalsBN = new BN(decimals);
  const divisor = new BN(10).pow(decimalsBN);

  const beforeDecimal = amount.div(divisor);
  const afterDecimal = amount.mod(divisor);

  if (afterDecimal.isZero()) {
    return beforeDecimal.toString();
  }

  // Fix the decimal places and concatenate mod with quotient
  return beforeDecimal.toString() + "." + trimTrailingZeros(prependZeros(afterDecimal.toString(), decimals));
}

export function prependZeros(s: string, decimals: number) {
  if (s === "" || s.length === decimals) {
    return s;
  }

  const diff = decimals - s.length;
  if (diff > 0) {
    return "0".repeat(diff) + s;
  }
  return s;
}

export function trimTrailingZeros(s: string) {
  for (let i = s.length -1; i >= 0; i--) {
    const c = s[i];
    if (c !== '0') {
      return s.substring(0, i+1);
    }
  }
  return s;
}

export function countLeadingZerosInDecimal(s: string) {
  const parts = s.split(".");
  if (parts.length !== 2) {
    return 0;
  }

  let zeros = 0;
  for (let i = 0; i < parts[1].length; i++) {
    const digit = parts[1][i];
    if (digit !== "0") {
      break;
    }

    zeros++;
  }

  return zeros;
}

export function weightToBasis(weight: number) {
  return weight * ONE_PERCENT_BASIS;
}

export function getTVLDevnet(mints: PublicKey[], balances: BN[]) {
  let tvl = 0;

  for (let i = 0; i < mints.length; i++) {
    const mint = mints[i];
    const balance = balances[i];

    let price = DEVNET_MINTS_TO_PRICE.get(mint.toBase58());
    if (!price) {
      price = 0;
    }

    const displayedBal = parseFloat(displayU64TokenAmount(balance));
    const val = displayedBal * price;
    tvl += val;
  }

  return tvl;
}

export function getTokenBalance(mint: string, userAccounts: TokenAccount[]) {
  let mappedMint = MAINNET_MINTS_TO_DEV.get(mint);
  if (!mappedMint) {
    mappedMint = mint;
  }

  const index = userAccounts.findIndex(
      (acc) => acc.info.mint.toBase58() === mappedMint
  );

  if (index !== -1) {
    return parseFloat(displayU64TokenBalance(userAccounts[index]));
  }

  return 0;
}

export function swapFeeToDecimal(swapFee: number) {
  return new Decimal(swapFee).div(new Decimal(FEE_DENOMINATOR));
}

export function calcSpotPrice(tokenBalanceIn: BN, tokenWeightIn: number, tokenBalanceOut: BN, tokenWeightOut: number, swapFee: number) {
  const numer = new Decimal(tokenBalanceIn.toString(10)).div(new Decimal(tokenWeightIn));
  const denom = new Decimal(tokenBalanceOut.toString(10)).div(new Decimal(tokenWeightOut));
  const ratio = numer.div(denom);
  const scale = new Decimal(1).div((new Decimal(1).sub(swapFeeToDecimal(swapFee))));
  const spotPrice = ratio.mul(scale);
  return spotPrice;
}

export function calcOutGivenIn(tokenBalanceIn: BN, tokenWeightIn: number, tokenBalanceOut: BN, tokenWeightOut: number, tokenAmountIn: BN, swapFee: number) {
  const weightRatio = new Decimal(tokenWeightIn).div(new Decimal(tokenWeightOut));
  const adjustedIn = new Decimal(tokenAmountIn.toString(10)).times((new Decimal(1).minus(swapFeeToDecimal(swapFee))));
  const y = new Decimal(tokenBalanceIn.toString(10)).div(new Decimal(tokenBalanceIn.toString(10)).plus(adjustedIn));
  const foo = y.pow(weightRatio);
  const bar = new Decimal(1).minus(foo);
  const tokenAmountOut = new Decimal(tokenBalanceOut.toString(10)).times(bar);
  return tokenAmountOut;
}

export function calcInGivenOut(tokenBalanceIn: BN, tokenWeightIn: number, tokenBalanceOut: BN, tokenWeightOut: number, tokenAmountOut: BN, swapFee: number) {
  const weightRatio = new Decimal(tokenWeightOut).div(new Decimal(tokenWeightIn));
  const diff = new Decimal(tokenBalanceOut.toString(10)).minus(new Decimal(tokenAmountOut.toString(10)));
  const y = new Decimal(tokenBalanceOut.toString()).div(diff);
  const foo = y.pow(weightRatio).minus(new Decimal(1));
  const tokenAmountIn = (new Decimal(tokenBalanceIn.toString(10)).times(foo)).div(new Decimal(1).minus(new Decimal(swapFeeToDecimal(swapFee))));
  return tokenAmountIn;
}

export function decimalU64ToFloat(d: Decimal) {
  let dStr = d.toString();
  if (dStr.includes(".")) {
    dStr = dStr.split(".")[0];
  }

  return parseFloat(displayU64TokenAmount(new BN(dStr)));
}