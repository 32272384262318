import { TokenAccount } from "../models";
import { useAccountsContext } from "./../contexts/accounts";

// TODO: when looking for user token accounts, search for associated ones
export function useUserAccounts() {
  const context = useAccountsContext();
  return {
    userAccounts: context.userAccounts as TokenAccount[],
  };
}
