import React from "react";
import {
  WalletMultiButton,
} from "@solana/wallet-adapter-ant-design";
import {Button, Popover} from "antd";
import {Link, useHistory, useLocation} from "react-router-dom";
import {LABELS} from "../../constants";
import {Settings} from "../Settings";
import {SettingOutlined} from "@ant-design/icons";

export const AppBar = (props: { left?: JSX.Element; right?: JSX.Element }) => {
    const history = useHistory();
    const location = useLocation();

    const swapClassName = location.pathname === "/swap" ? "appbar-menu-button pink-highlight" : "appbar-menu-button";
    const poolsClassName = location.pathname === "/" ? "appbar-menu-button pink-highlight" : "appbar-menu-button";

    const TopBar = (
        <div className="App-Bar-right">

          {/*<div style={{verticalAlign: "middle"}}>*/}
          {/*  <Link to="/add_nft">*/}
          {/*    <Button type="text" size="large">*/}
          {/*      <p className="space-label">Add NFT</p>*/}
          {/*    </Button>*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <h3 onClick={() => history.push('/')} className={poolsClassName}>Pools</h3>
          <h3 onClick={() => history.push('/swap')} className={swapClassName}>Swap</h3>
          <WalletMultiButton type="primary" style={{verticalAlign: "middle"}}/>
          {/*<div style={{ marginLeft: 5 }} />*/}
          {/*<Popover*/}
          {/*  placement="topRight"*/}
          {/*  title={LABELS.SETTINGS_TOOLTIP}*/}
          {/*  content={<Settings />}*/}
          {/*  trigger="click"*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    shape="circle"*/}
          {/*    size="large"*/}
          {/*    type="text"*/}
          {/*    icon={<SettingOutlined />}*/}
          {/*  />*/}
          {/*</Popover>*/}
          {props.right}
        </div>
    );

    return TopBar;
};
