import React, {useMemo, useState} from "react";
import {Button} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {LABEL_SELECT_TOKEN} from "../../constants/finance";
import {TokenIcon} from "../TokenIcon";
import {useTokenName, useUserAccounts} from "../../hooks";
import {TokenSelectorModal} from "../TokenSelectorModal";
import {PublicKey} from "@solana/web3.js";
import {getTokenBalance} from "../../utils/utils";
import {useWallet} from "@solana/wallet-adapter-react";

export interface TokenSelectorProps {
    mint?: string;
    onSelect: (mint: string) => void;
    filteredMints?: PublicKey[] | string[];
    showBalance?: boolean;
}

export const TokenSelector = (props: TokenSelectorProps) => {
    const { connected } = useWallet();
    const { onSelect, filteredMints, mint, showBalance } = props;
    const { userAccounts } = useUserAccounts();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const tokenName = useTokenName(mint);

    const showModal = () => { setIsModalVisible(true); };
    const hideModal = () => { setIsModalVisible(false); };

    const onSelectToken = (mint: string) => {
      onSelect(mint);
      setIsModalVisible(false);
    };

    let balance = useMemo(() => {
        if (!connected || !mint) {
            return "Not signed in.";
        }
        return getTokenBalance(mint, userAccounts).toFixed(3);
    }, [connected, mint, userAccounts]);

    const selectorModal = <TokenSelectorModal
        onSelect={onSelectToken}
        isModalVisible={isModalVisible}
        onClose={hideModal}
        filteredMints={filteredMints} />;

    if (!mint) {
        return <>
            <Button onClick={showModal} size={"large"} style={{backgroundColor: "#344154"}}>
                {LABEL_SELECT_TOKEN}
                <DownOutlined className={"arrow-select"}/>
            </Button>
            {showBalance ? (
                <div style={{display: "block", marginTop: 5, color: "grey", fontFamily: "Readex Pro Medium"}}>Wallet: {balance}</div>
            ) : null}
            {selectorModal}
        </>;
    }

    return <>
        <Button onClick={showModal} size={"large"} style={{backgroundColor: "#344154"}}>
            <TokenIcon
                style={{
                    marginTop: "-0.2rem",
                    marginLeft: "-0.15rem",
                    marginRight: "0.5rem",
                }}
                mintAddress={mint}
            />
            <p style={{display: "inline", marginRight: 5}}>{tokenName}</p>
            <DownOutlined className={"arrow-select"}/>
        </Button>
        {showBalance ? (
            <h3 style={{display: "block", marginTop: 5, color: "grey", fontFamily: "Readex Pro Medium"}}>{balance}</h3>
        ) : null}
        {selectorModal}
    </>;
}