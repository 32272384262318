import {
  Account,
  AccountInfo,
  PublicKey,
  TransactionInstruction,
} from "@solana/web3.js";

import { AccountInfo as TokenAccountInfo, Token } from "@solana/spl-token";
import { TOKEN_PROGRAM_ID } from "../utils/ids";
import BN from "bn.js";
import {
  bytesToNumber,
  bytesToPubkeyArray,
  bytesToU32Array,
  bytesToU64TokenAmount,
  bytesToU64TokenAmountArray
} from "../utils/utils";

export interface TokenAccount {
  pubkey: PublicKey;
  account: AccountInfo<Buffer>;
  info: TokenAccountInfo;
}

export interface PoolAccount {
  pubkey: PublicKey;
  info: PoolAccountInfo;

  // For deposits to pool
  depositAmounts: BN[]; // max ammounts user will get back
  lpRequested: BN;

  // For withdraws to pool
  withdrawAmounts: BN[]; // minumum amounts user will get back
  lpWithdrawn: BN;
}

export type PoolAccountInfo = {
  version: number;
  id: PublicKey;
  lpMint: PublicKey;
  lpBalance: BN;
  controller: PublicKey;
  isFinalized: boolean;
  createdAt: Date;
  numMints: number;
  mints: PublicKey[];
  tokenAccounts: PublicKey[];
  weightsNum: number[];
  weightsDenom: number[];
  balances: BN[],
  swapFeeNum: number;
  swapFeeDenom: number;
  exitFeeNum: number;
  exitFeeDenom: number;
  ownerFeeNum: number;
  ownerFeeDenom: number;
}

export function deserializePoolAccount(pubkey: PublicKey, account: AccountInfo<Buffer>) {
  const data = account.data.toJSON().data;

  // Offsets in raw account
  const version = 8;
  const id = version + 4;
  const lpMint = id + 32;
  const lpBalance = lpMint + 32;
  const controller = lpBalance + 8;
  const isFinalized = controller + 32;
  const createdAt = isFinalized + 4; // 8 + 4 + 32 + 32 + 8 + 32 + 4

  const numMints = createdAt + 8;
  const mints = numMints + 4;
  const tokenAccounts = mints + 32*5;
  const weightsNum = tokenAccounts + 32*5;
  const weightsDenom = weightsNum + 4*5;
  const balances = weightsDenom + 4*5;

  const swapFeeNum = balances + (8*5);
  const swapFeeDenom = swapFeeNum + 4;
  const exitFeeNum = swapFeeDenom + 4;
  const exitFeeDenom = exitFeeNum + 4;
  const ownerFeeNum = exitFeeDenom + 4;
  const ownerFeeDenom = ownerFeeNum + 4;

  const createdAtDate = new Date(0)
  createdAtDate.setUTCSeconds(bytesToNumber(data.slice(createdAt, numMints)));

  const numMintsValue = bytesToNumber(data.slice(numMints, mints));

  let depositAmounts = [];
  let withdrawAmounts = [];
  for (let i = 0; i < numMintsValue; i++) {
    depositAmounts.push(new BN(0));
    withdrawAmounts.push(new BN(0));
  }

  return {
    pubkey: pubkey,
    info: {
      version: bytesToNumber(data.slice(version, id)),
      id: new PublicKey(data.slice(id, lpMint)),
      lpMint: new PublicKey(data.slice(lpMint, lpBalance)),
      lpBalance: bytesToU64TokenAmount(data.slice(lpBalance, controller)),
      controller: new PublicKey(data.slice(controller, isFinalized)),
      isFinalized: bytesToNumber(data.slice(isFinalized, createdAt)) == 1,
      // createdAt: new Date(parseInt(new BN(data.slice(createdAt, numMints)).toString())),
      createdAt: createdAtDate,

      numMints: numMintsValue,
      mints: bytesToPubkeyArray(data.slice(mints, tokenAccounts)),
      tokenAccounts: bytesToPubkeyArray(data.slice(tokenAccounts, weightsNum)),
      weightsNum: bytesToU32Array(data.slice(weightsNum, weightsDenom)),
      weightsDenom: bytesToU32Array(data.slice(weightsDenom, balances)),
      balances: bytesToU64TokenAmountArray(data.slice(balances, swapFeeNum)),

      swapFeeNum: bytesToNumber(data.slice(swapFeeNum, swapFeeDenom)),
      swapFeeDenom: -1,
      exitFeeNum: bytesToNumber(data.slice(exitFeeNum, exitFeeDenom)),
      exitFeeDenom: -1,
      ownerFeeNum: bytesToNumber(data.slice(ownerFeeNum, ownerFeeDenom)),
      ownerFeeDenom: -1,
    } as PoolAccountInfo,

    depositAmounts: depositAmounts,
    lpRequested: new BN(0),

    withdrawAmounts: withdrawAmounts,
    lpWithdrawn: new BN(0),
  }
}

export function approve(
  instructions: TransactionInstruction[],
  cleanupInstructions: TransactionInstruction[],
  account: PublicKey,
  owner: PublicKey,
  amount: number,
  autoRevoke = true,

  // if delegate is not passed ephemeral transfer authority is used
  delegate?: PublicKey
): Account {
  const tokenProgram = TOKEN_PROGRAM_ID;
  const transferAuthority = new Account();

  instructions.push(
    Token.createApproveInstruction(
      tokenProgram,
      account,
      delegate ?? transferAuthority.publicKey,
      owner,
      [],
      amount
    )
  );

  if (autoRevoke) {
    cleanupInstructions.push(
      Token.createRevokeInstruction(tokenProgram, account, owner, [])
    );
  }

  return transferAuthority;
}
