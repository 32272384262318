import React, {Dispatch, SetStateAction, useCallback, useMemo} from "react";
import {Alert, Button, Col, InputNumber, Progress, Row, Space, Table} from "antd";
import {SizeType} from "antd/lib/config-provider/SizeContext";
import {
    DEFAULT_BLUE, MAINNET_MINTS_TO_DEV,
    MAX_LP_TOKENS,
    MAX_LP_WEIGHT,
    MAX_TOKEN_WEIGHT,
    MIN_LP_TOKENS,
    MIN_TOKEN_WEIGHT,
    ONE_PERCENT_BASIS
} from "../../constants/finance";
import {displayPercent, getRandomKey, weightToBasis} from "../../utils/utils";
import {usePoolConfigState} from "../../hooks/usePoolConfigState";
import {TokenSelector} from "../TokenSelector";
import {CreatePoolStep} from "../../views/create/create";
import {LeftOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";

const trashIcon =
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           className="feather feather-trash-2">
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line x1="10" y1="11" x2="10" y2="17"></line>
        <line x1="14" y1="11" x2="14" y2="17"></line>
    </svg>;

export interface PoolTokenWeight {
    key: string;
    token: string;
    mint: string;
    weight: number;
    amount: number;
}

export interface SelectPoolTokenWeightsProps {
    setCreatePoolStep: Dispatch<SetStateAction<CreatePoolStep>>;
}

export const SelectPoolTokenWeights = (props: SelectPoolTokenWeightsProps) => {
    const { poolTokens, setPoolTokens } = usePoolConfigState();
    const history = useHistory();
    const { setCreatePoolStep } = props;

    const showDeleteToken = poolTokens.length > MIN_LP_TOKENS;

    const deleteTokenRow = (key: string) => {
        const newTokens = poolTokens.filter((t) => t.key !== key);

        setPoolTokens(newTokens);
    };

    const setTokenWeight = (key: string, weight: number) => {
        if (weight === null) {
            weight = 0;
        }

        let newTokens: PoolTokenWeight[] = JSON.parse(JSON.stringify(poolTokens));
        const target = newTokens.find((t) => t.key === key);
        if (target) {
            target.weight = weight;
            setPoolTokens(newTokens);
        }
    }

    const addTokenRow = () => {
        let newTokens: PoolTokenWeight[] = JSON.parse(JSON.stringify(poolTokens));
        let weight = Math.max((MAX_LP_WEIGHT - totalWeight) / ONE_PERCENT_BASIS, 0);
        const parts = weight.toString().split(".");
        // Hack due to floating point inconsistency 79999824.000052803 79999000.000427222
        if (parts.length > 1 && parts[1].length > 2) {
            weight = 0;
        }

        newTokens.push({
            key: getRandomKey(),
            token: '',
            mint: '',
            weight: weight,
            amount: 0,
        });

        setPoolTokens(newTokens);
    };

    const columns = [
        {
            title: 'Token',
            dataIndex: 'mint',
            key: 'token',
            render: (text: string, obj: any) => {
                const onSelectToken = (mint: string) => {
                    let newTokens: PoolTokenWeight[] = JSON.parse(JSON.stringify(poolTokens));
                    const targetIndex = newTokens.findIndex((poolTokenWeight) => {
                        return poolTokenWeight.key === obj["key"];
                    });

                    if (targetIndex !== -1) {
                        newTokens[targetIndex].mint = mint;
                        setPoolTokens(newTokens);
                    }
                };

                const usedTokens = poolTokens.map((poolToken) => poolToken.mint);

                return <TokenSelector mint={text} onSelect={onSelectToken} filteredMints={usedTokens}/>;
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (weight: string, obj: any) => {
                let w = parseFloat(weight);

                return (
                    <Space size="middle">
                        <InputNumber
                            onFocus={(e) => e.target.select()}
                            formatter={(num, info) => info.userTyping ? (num || 0).toString() : num + "%"}
                            size={"large"}
                            min={MIN_TOKEN_WEIGHT}
                            max={MAX_TOKEN_WEIGHT}
                            precision={2}
                            maxLength={6}
                            defaultValue={w}
                            style={{fontFamily: "Readex Pro Medium"}}
                            onChange={(num) => {
                                setTokenWeight(obj["key"], num);
                            }}
                        />

                        {
                            showDeleteToken ? (
                                <a style={{ display: "inline" }}
                                   onClick={() => {
                                       deleteTokenRow(obj["key"]);
                                   }}
                                >
                                    {trashIcon}
                                </a>
                            ) :
                            <></>
                        }
                    </Space>
                );
            },
        },
    ];

    // Default to blue
    let progressColor = DEFAULT_BLUE;
    let totalWeight = poolTokens.map((t: PoolTokenWeight) => t.weight).reduce((prev: number, curr: number) => prev + weightToBasis(curr), 0);

    // Check if any token or weight is invalid
    let isTokenBlank = poolTokens.findIndex(({mint}) => mint === "") !== -1;
    let isWeightBlank = poolTokens.findIndex(({weight}) => weight === null || weight === 0) !== -1;
    let isWeightRangeInvalid = poolTokens.findIndex(({weight}) => weight < 1.0 || weight > 100.0) !== -1;
    let isWeightDecimalInvalid = poolTokens.findIndex(({weight}) => {
        if (weight !== null) {
            const parts = weight.toString().split(".");
            return parts.length > 1 && parts[1].length > 2;
        }
        return false;
    }) !== -1;

    // Color for the progress bar
    if (totalWeight > MAX_LP_WEIGHT || isWeightBlank || isWeightRangeInvalid || isWeightDecimalInvalid) {
        progressColor = "red";
    } else if (totalWeight === MAX_LP_WEIGHT) {
        progressColor = "#2CB084"
    }

    const areRowsValid = totalWeight === MAX_LP_WEIGHT && !isTokenBlank && !isWeightBlank && !isWeightRangeInvalid && !isWeightDecimalInvalid;

    // Determine error message if there is a problem
    let message = "";
    let messageType: "info" | "success" | "warning" | "error" | undefined = "error";
    if (isWeightBlank || isWeightRangeInvalid) {
        message = "Each token's weight must be between 1% and 99%.";
    } else if (isWeightDecimalInvalid) {
        message = "Each token's weight % can only have a max of 2 decimal places, like \"33.34%\".";
    } else if (totalWeight !== MAX_LP_WEIGHT) {
        message = "The total weight of all tokens in the pool must be exactly 100%";
    } else if (isTokenBlank) {
        message = "Please select a token for each row.";
        messageType = "info";
    }

    const errorMessage = message === "" ? <></>:
        <Alert
            message={message}
            type={messageType}
            style={{marginTop: 30, margin: "auto"}}
            showIcon />;

    const handleContinue = () => {
        setCreatePoolStep("fees");
    }

    const goBack = () => history.push('/');

    const panel = (
        <div>
            <LeftOutlined
                className={"arrow-select"}
                style={{marginRight: 5, verticalAlign: "text-top"}}
                onClick={goBack}
            />
            <h1 style={{display: "inline"}}>Specify Tokens & Weights</h1>
            <Table style={{marginTop: 10}} columns={columns} dataSource={poolTokens} size={"large"} pagination={false}/>
            <Row gutter={[16, 16]}>
                <Button
                    disabled={poolTokens.length === MAX_LP_TOKENS}
                    size="large"
                    style={{backgroundColor: "transparent", borderColor: "#FE2598", color: "#626262", marginTop: 15, marginLeft: 25}}
                    onClick={addTokenRow}
                >
                    Add Token
                </Button>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <h3 style={{ marginTop: 15 }}>Weight Allocated</h3>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Progress style={{marginBottom: 15, width: "93%"}} percent={displayPercent(totalWeight)} status="normal" strokeColor={progressColor}/>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                {errorMessage}
                <Col span={24}>
                    <Button
                        disabled={!areRowsValid}
                        className={"continue-button-gradient"}
                        size="large"
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </Col>
            </Row>
        </div>
    );

    return panel;
};
