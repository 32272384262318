import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, InputNumber, Row, Space, Table} from "antd";
import {useAnchorWallet, useWallet} from "@solana/wallet-adapter-react";
import {useConnection, useConnectionConfig} from "../../contexts/connection";
import {notify} from "../../utils/notifications";
import {getAllPools, swapTokenIn} from "../../actions/millionfi";
import {PoolAccount} from "../../models";
import {
    calcInGivenOut,
    calcOutGivenIn,
    calcSpotPrice, decimalU64ToFloat, displayU64TokenAmount,
    getTokenBalance, getTokenName,
    getTVLDevnet,
    numberToU64TokenAmount
} from "../../utils/utils";
import {useUserAccounts} from "../../hooks";
import BN from "bn.js";
import {LeftOutlined, SwapOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {TokenSelector} from "../../components/TokenSelector";
import {cloneDeep, isEqual} from "lodash";
import {Decimal} from "decimal.js";
import {MAINNET_MINTS_TO_DEV} from "../../constants/finance";
import {Keypair, PublicKey} from "@solana/web3.js";

export interface SwapTokenPayload {
    mintA: string;
    mintB: string;
    amountA: number;
    amountB: number;
    currSpotPrice: Decimal;
}

export const SwapView = () => {
    const connection = useConnection();
    const anchorWallet = useAnchorWallet();
    const { wallet, connected } = useWallet();
    const { userAccounts } = useUserAccounts();
    const history = useHistory();
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const { tokenMap } = useConnectionConfig();
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState<"info" | "success" | "warning" | "error" | undefined>("info");

    const [pools, setPools] = useState<PoolAccount[]>([]);
    const [swapPool, setSwapPool] = useState<PoolAccount | null>(null);
    const [refreshPool, setRefreshPool] = useState<PublicKey>(Keypair.generate().publicKey);
    const [swapTokenPayload, setSwapTokenPayload] = useState<SwapTokenPayload>({
        mintA: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        mintB: "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
        amountA: 0,
        amountB: 0,
        currSpotPrice: new Decimal(0),
    });

    // TODO: use context and merge with code in home view
    useEffect(() => {
        const fetchPools = async () => {
            let res = await getAllPools(connection);
            res = res.sort((a, b) => {
                const tvlA = getTVLDevnet(a.info.mints, a.info.balances);
                const tvlB = getTVLDevnet(b.info.mints, b.info.balances);

                if (tvlA === tvlB) return 0;
                return tvlA > tvlB ? -1 : 1;
            });

            setPools(res);
        }

        fetchPools().catch(console.error);
    }, [anchorWallet, connection, refreshPool])

    // Set target swap pool based on best price
    // TODO: smarter routing and optimize/refactor loops
    useEffect(() => {
        // Need these to calculate best pool
        if (!swapTokenPayload.mintA || !swapTokenPayload.mintB || (swapTokenPayload.amountA !== 0 && swapTokenPayload.amountB !== 0)) {
            return;
        }

        const findMintInPool = (pool: PoolAccount, target: string) => {
            return pool.info.mints.findIndex((mint) => mint.toBase58() === target || mint.toBase58() === MAINNET_MINTS_TO_DEV.get(target));
        }

        let bestCandidate = -1;
        let bestAmountIn = new Decimal(Infinity);
        let bestAmountOut = new Decimal(0);
        let spotPrice = new Decimal(0);
        let defaultSpotPrice = new Decimal(Infinity);

        // Get pool with the best price (most tokens out)
        for (let i = 0; i < pools.length; i++) {
            const candidate = pools[i];
            const mintAIndex = findMintInPool(candidate, swapTokenPayload.mintA);
            const mintBIndex = findMintInPool(candidate, swapTokenPayload.mintB);

            // Pair does not exist in this pool
            if (mintAIndex === -1 || mintBIndex === -1) continue;

            const currSpotPrice = calcSpotPrice(
                candidate.info.balances[mintAIndex],
                candidate.info.weightsNum[mintAIndex],
                candidate.info.balances[mintBIndex],
                candidate.info.weightsNum[mintBIndex],
                candidate.info.swapFeeNum,
            );

            defaultSpotPrice = Decimal.min(defaultSpotPrice, currSpotPrice);

            if (swapTokenPayload.amountA !== 0) {
                const outGivenIn = calcOutGivenIn(
                    candidate.info.balances[mintAIndex],
                    candidate.info.weightsNum[mintAIndex],
                    candidate.info.balances[mintBIndex],
                    candidate.info.weightsNum[mintBIndex],
                    numberToU64TokenAmount(swapTokenPayload.amountA),
                    candidate.info.swapFeeNum,
                );

                if (outGivenIn.greaterThanOrEqualTo(bestAmountOut)) {
                    bestCandidate = i;
                    bestAmountOut = outGivenIn;

                    // Also set spot price for best pool so far
                    spotPrice = currSpotPrice;
                }
            } else if (swapTokenPayload.amountB !== 0) {
                // Requested tokens more than max in this pool
                if (numberToU64TokenAmount(swapTokenPayload.amountB).gt(candidate.info.balances[mintBIndex])) {
                    continue;
                }

                const inGivenOut = calcInGivenOut(
                    candidate.info.balances[mintAIndex],
                    candidate.info.weightsNum[mintAIndex],
                    candidate.info.balances[mintBIndex],
                    candidate.info.weightsNum[mintBIndex],
                    numberToU64TokenAmount(swapTokenPayload.amountB),
                    candidate.info.swapFeeNum,
                );

                if (inGivenOut.lessThanOrEqualTo(bestAmountIn)) {
                    bestCandidate = i;
                    bestAmountIn = inGivenOut;

                    // Also set spot price for best pool so far
                    spotPrice = currSpotPrice;
                }
            }
        }

        if (bestCandidate !== -1) {
            const newPool = cloneDeep(pools[bestCandidate]);
            const newPayload = cloneDeep(swapTokenPayload);

            if (!bestAmountIn.equals(new Decimal(Infinity))) {
                newPayload.amountA = decimalU64ToFloat(bestAmountIn);
            } else if (!bestAmountOut.isZero()) {
                newPayload.amountB = decimalU64ToFloat(bestAmountOut);
            }
            newPayload.currSpotPrice = spotPrice;

            if (!isEqual(swapTokenPayload, newPayload)) {
                setSwapPool(newPool);
                setSwapTokenPayload(newPayload);
            }
        } else if (!defaultSpotPrice.isZero()) {
            const newPayload = cloneDeep(swapTokenPayload);
            newPayload.currSpotPrice = defaultSpotPrice;

            if (!isEqual(swapTokenPayload, newPayload)) {
                setSwapTokenPayload(newPayload);
            }
        }

    }, [pools, swapTokenPayload]);

    const goBack = () => history.push('/');

    const errorMessage = useMemo(() => {
        return message === "" ? <></>:
            <Alert
                message={message}
                type={messageType}
                style={{margin: "auto", marginTop: 10}}
                showIcon />;
    }, [message, messageType]);

    // Determine any error messages
    useEffect(() => {
        if (!connected) {
            setMessage("Sign in to your wallet to proceed.");
            setMessageType("info");
            return;
        }

        if (swapTokenPayload.amountA === 0 && swapTokenPayload.amountB === 0) {
            setMessage("Set token amount to swap.");
            setMessageType("info");
        } else if (swapTokenPayload.amountA > getTokenBalance(swapTokenPayload.mintA, userAccounts)) {
            setMessage("Amount exceeds your wallet balance.");
            setMessageType("error");
        } else if (!swapPool) {
            setMessage("Insufficient liquidity.");
            setMessageType("error");
        } else {
            setMessage("");
            setMessageType("info");
        }
    }, [connected, swapTokenPayload, swapPool, userAccounts]);

    const dataSource = useMemo(() => {
        return [
            {
                key: 0,
                mint: swapTokenPayload.mintA,
                amount: swapTokenPayload.amountA,
            },
            {
                key: 1,
                mint: swapTokenPayload.mintB,
                amount: swapTokenPayload.amountB,
            }
        ];
    }, [swapTokenPayload]);

    const setSelectedToken = useCallback((key: number, mint: string) => {
        const newPayload = cloneDeep(swapTokenPayload);
        if (key === 0) {
            newPayload.mintA = mint;
            newPayload.amountA = 0;
        } else {
            newPayload.mintB = mint;
            newPayload.amountB = 0;
        }
        setSwapTokenPayload(newPayload);
    }, [swapTokenPayload]);

    const setSelectedAmount = useCallback((key: number, mint: string, amount: number) => {
        // Reset chosen pool to swap with
        setSwapPool(null);

        const newPayload = cloneDeep(swapTokenPayload);
        if (key === 0) {
            newPayload.mintA = mint;
            newPayload.amountA = amount;
            newPayload.amountB = 0;
        } else {
            newPayload.mintB = mint;
            newPayload.amountB = amount;
            newPayload.amountA = 0;
        }
        setSwapTokenPayload(newPayload);
    }, [swapTokenPayload]);

    const filteredTokens = useMemo(() => [
        (swapTokenPayload.mintA || "").toString(),
        (swapTokenPayload.mintB || "").toString(),
    ], [swapTokenPayload]);

    const columns = useMemo(() => [
        {
            title: 'Token',
            dataIndex: 'mint',
            key: 'token',
            render: (mint: string, obj: any) => {
                return <TokenSelector
                    mint={mint}
                    onSelect={(mint) => setSelectedToken(obj["key"], mint)}
                    filteredMints={filteredTokens}
                    showBalance={true}
                />
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number, obj: any) => {
                // Set red border if amount is invalid
                let inputClass = "";
                const balance = getTokenBalance(obj["mint"], userAccounts);
                if (amount > balance || amount === 0) {
                    inputClass = "invalid-input-border";
                }

                return (
                    <>
                        <InputNumber
                            onFocus={(e) => e.target.select()}
                            className={inputClass}
                            defaultValue={amount}
                            value={amount}
                            size={"large"}
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            style={{display: "block", width: "100%", fontFamily: "Readex Pro Medium"}}
                            onChange={(num) => {
                                setSelectedAmount(obj["key"], obj["mint"], num);
                            }}
                        />
                        <div style={{display: "block", marginTop: 5}}>
                            <h3 style={{color: "#8657FD", cursor: "pointer"}} onClick={() => setSelectedAmount(obj["key"], obj["mint"], getTokenBalance(obj["mint"], userAccounts))}>
                                Max
                            </h3>
                        </div>
                    </>
                );
            },
        },
    ], [filteredTokens, setSelectedToken, setSelectedAmount, userAccounts]);

    const swapIcon = useMemo(() => {
        const onSwapSwitch = () => {
            setSwapTokenPayload({
                mintA: swapTokenPayload.mintB,
                mintB: swapTokenPayload.mintA,
                amountA: 0,
                amountB: 0,
                currSpotPrice: new Decimal(0),
            });
        };

        return swapTokenPayload.mintA && swapTokenPayload.mintB ?
                <SwapOutlined onClick={onSwapSwitch} className={"swap-outlined-icon"} /> : <></>;
    }, [swapTokenPayload]);

    const spotPriceLabel = useMemo(() => {
        if (!swapTokenPayload.mintA || !swapTokenPayload.mintB || swapTokenPayload.currSpotPrice.isZero()) {
            return <></>;
        }

        const mintAName = getTokenName(tokenMap, swapTokenPayload.mintA);
        const mintBName = getTokenName(tokenMap, swapTokenPayload.mintB);
        const formattedPrice = new Decimal(1).div(swapTokenPayload.currSpotPrice).toDecimalPlaces(9).toString();

        return <h3>1 {mintAName} ≈ {formattedPrice} {mintBName}</h3>;
    }, [tokenMap, swapTokenPayload])

    // TODO: smart routing
    const handleSwap = useCallback(async () => {
        if (anchorWallet && wallet && swapPool) {
            // Extreme price variation due to pools with high variance for now
            console.log(JSON.stringify(swapTokenPayload, null, 2));

            // TODO: play around with price impact limitation
            // const maxPriceFrac = swapTokenPayload.currSpotPrice.mul(1000).div(1).toFraction(new Decimal(10**6));
            const maxPriceFrac = new Decimal(1).toFraction(new Decimal(10**6));

            console.log(
                "in for out:",
                numberToU64TokenAmount(swapTokenPayload.amountA).toString(10),
                numberToU64TokenAmount(swapTokenPayload.amountB).toString(10)
            );
            setButtonEnabled(false);
            try {
                await swapTokenIn(connection, anchorWallet, wallet.adapter, swapPool, userAccounts,
                    new PublicKey(MAINNET_MINTS_TO_DEV.get(swapTokenPayload.mintA) || ""),
                    new PublicKey(MAINNET_MINTS_TO_DEV.get(swapTokenPayload.mintB) || ""),
                    numberToU64TokenAmount(swapTokenPayload.amountA),
                    // Default 1% slippage for now
                    numberToU64TokenAmount(swapTokenPayload.amountB).div(new BN(100)).mul(new BN(99)),
                    new BN(maxPriceFrac[0].toString()),
                    new BN(maxPriceFrac[1].toString()));

                // Reset swap
                setRefreshPool(Keypair.generate().publicKey);
                setSwapPool(null);
                const resetPayload = cloneDeep(swapTokenPayload);
                resetPayload.amountA = 0;
                resetPayload.amountB = 0;
                setSwapTokenPayload(resetPayload);
            } catch (e) {
                console.error(e);
                notify({ message: "Failed swap.", type: "error" });
            }
            setButtonEnabled(true);
        }
    }, [anchorWallet, wallet, connection, swapPool, swapTokenPayload, userAccounts]);

    return (
        <div className="flexColumn" style={{ flex: 1 }}>
            <div style={{marginTop: 40}}>
                <div style={{margin: "auto", padding: 7.5, maxWidth: 450, backgroundColor: "#0A0A0A", borderRadius: 20}} >
                    <h3 style={{color: "grey"}}>&#60;Solana Devnet&#62;</h3>
                    <div>
                        <LeftOutlined
                            className={"arrow-select"}
                            style={{marginRight: 5, verticalAlign: "text-top", fontWeight: "Readex Pro Bold"}}
                            onClick={goBack}
                        />
                        <h1 style={{display: "inline"}}>Swap</h1>
                        <Table style={{marginTop: 10}} columns={columns} dataSource={dataSource} size={"large"} pagination={false}/>
                        {spotPriceLabel}
                        {swapIcon}
                        <h3>Slippage: 1%</h3>
                        <Row gutter={[16, 16]}>
                            {errorMessage}
                            <Col span={24}>
                                <Button
                                    disabled={!connected || !!message || !buttonEnabled}
                                    className={"continue-button-gradient"}
                                    size="large"
                                    onClick={handleSwap}
                                >
                                    Confirm
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}
