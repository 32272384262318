import {Alert, Button, Col, Row} from "antd";
import React, {useEffect, useState} from "react";
import {useConnection} from "../../contexts/connection";
import {useAnchorWallet} from "@solana/wallet-adapter-react";
import {getAllPools} from "../../actions/millionfi";
import {PublicKey} from "@solana/web3.js";
import {PoolAccountInfo} from "../../models";
import {MultiPoolIcon} from "../../components/TokenIcon";
import {DEVNET_MINTS_TO_MAIN, ONE_PERCENT_BASIS} from "../../constants/finance";
import {TokenDisplay} from "../../components/TokenDisplay";
import {useHistory} from "react-router-dom";
import {formatNumber, getTVLDevnet} from "../../utils/utils";

export const HomeView = () => {
  const connection = useConnection();
  const anchorWallet = useAnchorWallet();
  const [pools, setPools] = useState<{ pubkey: PublicKey, info: PoolAccountInfo }[]>([]);
  const history = useHistory();

  useEffect(() => {
    const fetchPools = async () => {
      let res = await getAllPools(connection);
      res = res.sort((a, b) => {
        const tvlA = getTVLDevnet(a.info.mints, a.info.balances);
        const tvlB = getTVLDevnet(b.info.mints, b.info.balances);

        if (tvlA === tvlB) return 0;
        return tvlA > tvlB ? -1 : 1;
      });

      setPools(res);
    }

    fetchPools().catch(console.error);
  }, [anchorWallet, connection])

  const poolPanels = [];
  let totalTVL = 0;

  for (let i = 0; i < pools.length; i++) {
    const pool = pools[i];
    const mainMints = pool.info.mints.map((pk) => {
      const mappedMint = DEVNET_MINTS_TO_MAIN.get(pk.toBase58());
      if (!mappedMint) {
        return "";
      }
      return mappedMint;
    }).filter((mint) => mint != "");

    const tokenDisplays = mainMints
        .map((m, idx) =>
            // <div style={{margin: 1}}>
              <TokenDisplay
                  className={"home-token-display"}
                  hideIcon={true}
                  weight={pool.info.weightsNum[idx] / ONE_PERCENT_BASIS}
                  mintAddress={m}
                  buttonSize={"large"}/>
            );

    const tvl = getTVLDevnet(pool.info.mints, pool.info.balances);
    totalTVL += tvl;

    const panel =
      <div className="pool-panel">
        <Row>
          <Col span={12}>
            <h2 style={{textAlign: "left"}}>${formatNumber.format(Math.ceil(tvl))}</h2>
          </Col>
          <Col span={12}>
            <Button size={"large"} className={"pool-panel-deposit-button"} onClick={() => history.push("/deposit/" + pool.info.id)} style={{display: "inline-flex", float: "right", fontFamily: "Readex Pro Medium"}}>Invest</Button>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <MultiPoolIcon className={"multi-pool-icon"} mints={mainMints} />
          </Col>
        </Row>

        <Row align={"middle"} justify={"start"} style={{rowGap: 10, marginLeft: 0, marginRight: 0, marginTop: 14}} gutter={[16, 16]}>
          {tokenDisplays}
        </Row>
      </div>;

    poolPanels.push(panel);
  }

  return (
    <Row gutter={[16, 16]}>
      <div className="flexColumn" style={{ margin: "auto", flex: 1, marginTop: 15, maxWidth: 450 }}>
        <Row align={"middle"} style={{paddingLeft: 5, paddingRight: 5, marginBottom: 10}}>
          <Col span={24}>
            <Alert
                style={{borderRadius: 10, backgroundColor: "#0A0A0A", borderColor: "#0A0A0A", width: "100%", paddingBottom: 10}}
                message={(
                    <Row justify={"center"} align={"middle"}>
                      <h3 style={{marginBottom: 0}}>
                        Devnet is live! Get test tokens
                        <span onClick={() => history.push('/faucet')} style={{color: "#FE2598", cursor: "pointer"}}> here</span>.
                      </h3>
                    </Row>)}
                description=""
                type="info"
            />
          </Col>
        </Row>

        <Row style={{marginLeft: 10}}>
          <Col span={24}>
            <h2 style={{ float: "left", marginBottom: -5, color: "grey"}}>Total Value Locked</h2>
          </Col>
        </Row>
        <Row style={{marginLeft: 10}}>
          <Col span={24}>
            <h2 style={{ float: "left"}}>${formatNumber.format(totalTVL)} </h2>
          </Col>
        </Row>

        <Row align={"middle"} style={{marginLeft: 10, marginBottom: 5}}>
          <Col span={12}>
            <Row>
              <h2 style={{color: "grey", marginBottom: -5}}>Solana Devnet</h2>
            </Row>
            <h2 style={{ float: "left"}}>All Index Pools</h2>
          </Col>
          <Col span={12}>
            <Button size={"large"} onClick={() => history.push("/create")} style={{border: "solid 1px", float: "right", marginBottom: 10, marginRight: 20, fontFamily: "Readex Pro Medium"}}>Create Pool</Button>
          </Col>
        </Row>
        <div className={"pools-list"}>
          {poolPanels}
        </div>
      </div>
    </Row>
  );
};
