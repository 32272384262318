import { HashRouter, Route, Switch } from "react-router-dom";
import React, { useMemo } from "react";
import { WalletProvider } from "@solana/wallet-adapter-react";
import { ConnectionProvider } from "./contexts/connection";
import { AccountsProvider } from "./contexts/accounts";
import { AppLayout } from "./components/Layout";

import { HomeView } from "./views";
import {
  PhantomWalletAdapter,
  SolletWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {FaucetView} from "./views/faucet/faucet";
import {CreateView} from "./views/create/create";
import {PoolConfigProvider} from "./contexts/pools";
import {DepositView} from "./views/deposit/deposit";
import {WithdrawView} from "./views/withdraw/withdraw";
import {SwapView} from "./views/swap";

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-ant-design/styles.css');

export function Routes() {
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolletWalletAdapter(),
      new SolflareWalletAdapter(),
    ],
    []
  );

  return (
    <HashRouter basename={"/"}>
      <ConnectionProvider>
        <WalletProvider wallets={wallets} autoConnect>
          <AccountsProvider>
            <AppLayout>
              <Switch>
                <Route exact path="/" component={() => <HomeView />} />
                <Route exact path="/faucet" children={<FaucetView />} />
                <Route exact path="/create" render={({match}) => (
                    <PoolConfigProvider>
                      <CreateView />
                    </PoolConfigProvider>
                )} />
                <Route path="/deposit/:poolId" render={({match}) => (
                  <DepositView poolId={match.params.poolId}/>
                )} />
                <Route path="/withdraw/:poolId" render={({match}) => (
                  <WithdrawView poolId={match.params.poolId}/>
                )} />
                <Route exact path="/swap" component={() => <SwapView />} />
              </Switch>
            </AppLayout>
          </AccountsProvider>
        </WalletProvider>
      </ConnectionProvider>
    </HashRouter>
  );
}
