import { Identicon } from "../Identicon";
import React from "react";
import { getTokenIcon } from "../../utils/utils";
import { useConnectionConfig } from "../../contexts/connection";
import { PublicKey } from "@solana/web3.js";

export const TokenIcon = (props: {
  mintAddress?: string | PublicKey;
  style?: React.CSSProperties;
  size?: number;
  className?: string;
}) => {
  const { tokenMap } = useConnectionConfig();
  const icon = getTokenIcon(tokenMap, props.mintAddress);

  const size = props.size || 24;

  if (icon) {
    return (
      <img
        alt="Token icon"
        className={props.className}
        key={icon}
        width={props.style?.width || size.toString()}
        height={props.style?.height || size.toString()}
        src={icon}
        style={{
          borderRadius: "10rem",
          backgroundColor: "white",
          backgroundClip: "padding-box",
          ...props.style,
        }}
      />
    );
  }

  return (
    <Identicon
      address={props.mintAddress}
      style={{
        marginRight: "0.5rem",
        width: size,
        height: size,
        ...props.style,
      }}
    />
  );
};

export const MultiPoolIcon = (props: {
    mints: string[] | PublicKey[];
    style?: React.CSSProperties;
    className?: string;
}) => {
    const tokenIcons = props.mints.map((mint, index) => (
        <TokenIcon
            mintAddress={mint}
            style={{ marginRight: `${"-1rem"}`, ...props.style}}
            size={40}
        />
    ));

    return (
        <div className={props.className} style={{ maxWidth: 300, display: "inline-flex" }}>
            {tokenIcons}
        </div>
    );
};


export const PoolIcon = (props: {
  mintA: string;
  mintB: string;
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div className={props.className} style={{ display: "flex" }}>
      <TokenIcon
        mintAddress={props.mintA}
        style={{ marginRight: "-0.5rem", ...props.style }}
      />
      <TokenIcon mintAddress={props.mintB} />
    </div>
  );
};
