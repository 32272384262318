import React, {Dispatch, SetStateAction, useState} from 'react';
import {CreatePoolStep} from "../../views/create/create";
import {LeftOutlined} from "@ant-design/icons";
import {Button, InputNumber} from "antd";
import {normalizeFeePercent} from "../../utils/utils";
import {usePoolConfigState} from "../../hooks/usePoolConfigState";
import {FEE_DENOMINATOR} from "../../constants/finance";

export interface SelectPoolFeesProps {
    setCreatePoolStep: Dispatch<SetStateAction<CreatePoolStep>>;
}

export const SWAP_FEE_BUTTON_1_KEY = "fee1";
export const SWAP_FEE_BUTTON_2_KEY = "fee2";
export const SWAP_FEE_BUTTON_3_KEY = "fee3";
export const SWAP_FEE_BUTTON_4_KEY = "fee4";

export const SelectPoolFees = (props: SelectPoolFeesProps) => {
    const { selectedKey, setSelectedKey, fee, setFee } = usePoolConfigState();
    const { setCreatePoolStep } = props;

    const goBack = () => {
        setCreatePoolStep("tokens");
    }

    const getHighlightedClass = (targetKey: string) => {
        return selectedKey === targetKey ? "swap-select" : "";
    }

    const updateFeeClick = (key: string, newFee: number) => {
        return () => {
            setSelectedKey(key);
            setFee(newFee)
        };
    }

    const updateFeeOnInputChange = (val: number) => {
        setSelectedKey(SWAP_FEE_BUTTON_4_KEY);
        setFee(val);
    };

    let defaultFee = 1;
    if (selectedKey === SWAP_FEE_BUTTON_4_KEY) {
        defaultFee = fee;
    }

    const inputNumber = <InputNumber
        onFocus={(e) => e.target.select()}
        formatter={(num, info) => info.userTyping ? (num || 0).toString() : (num || 0).toString() + "%"}
        className={getHighlightedClass(SWAP_FEE_BUTTON_4_KEY)}
        key={SWAP_FEE_BUTTON_4_KEY}
        defaultValue={defaultFee}
        style={{marginRight: 10, textAlign: "right", maxWidth: 90, fontFamily: "Readex Pro Medium"}}
        min={0.0001}
        max={10}
        maxLength={7}
        precision={4}
        step={0.0001}
        onChange={updateFeeOnInputChange}
    />;

    const handleContinue = () => {
        setCreatePoolStep("liquidity");
    }

    return <div>
        <LeftOutlined
            className={"arrow-select"}
            style={{marginRight: 5, verticalAlign: "text-top"}}
            onClick={goBack}
        />
        <h1 style={{display: "inline"}}>Set Swap Fees</h1>
        <p style={{marginTop: 5}}>0.3% is optimal for most weighted pools.</p>

        <Button className={getHighlightedClass(SWAP_FEE_BUTTON_1_KEY)} key={SWAP_FEE_BUTTON_1_KEY} onClick={updateFeeClick(SWAP_FEE_BUTTON_1_KEY, 0.1)} style={{marginRight: 10, maxWidth: 65}}>0.1%</Button>
        <Button className={getHighlightedClass(SWAP_FEE_BUTTON_2_KEY)} key={SWAP_FEE_BUTTON_2_KEY} onClick={updateFeeClick(SWAP_FEE_BUTTON_2_KEY, 0.3)} style={{marginRight: 10, maxWidth: 65}}>0.3%</Button>
        <Button className={getHighlightedClass(SWAP_FEE_BUTTON_3_KEY)} key={SWAP_FEE_BUTTON_3_KEY} onClick={updateFeeClick(SWAP_FEE_BUTTON_3_KEY, 1.0)} style={{marginRight: 10, maxWidth: 65}}>1.0%</Button>
        {inputNumber}
        <Button
            className={"continue-button-gradient"}
            size="large"
            onClick={handleContinue}
            style={{marginTop: 15}}
        >
            Continue
        </Button>
    </div>
}