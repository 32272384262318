import React from "react";
import "./../../App.less";
import { Layout } from "antd";
import {Link} from "react-router-dom";
import { WalletModalProvider } from "@solana/wallet-adapter-ant-design";
import logo from "../../media/million-logo.png";

import { AppBar } from "../AppBar";

const { Header, Content } = Layout;

export const AppLayout = React.memo(({ children }) => {
  return (
    <WalletModalProvider>
      <div className="App wormhole-bg">
        <Layout>
          <Header className="App-Bar">
            <Link to='/'>
              <img className="million-logo" src={logo} />
              <h2 className="app-title label-hover">Million Finance</h2>
            </Link>
            <AppBar />
          </Header>
          <Content style={{ padding: "0 50px", backgroundColor: "#1C1C1C" }}>{children}</Content>
        </Layout>
      </div>
    </WalletModalProvider>
  );
});
