export type Millionfi = {
  "version": "0.1.0",
  "name": "millionfi",
  "instructions": [
    {
      "name": "createPool",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lpMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "lpMintBump",
          "type": "u8"
        },
        {
          "name": "numMints",
          "type": "u8"
        },
        {
          "name": "swapFeeNum",
          "type": "u32"
        },
        {
          "name": "swapFeeDenom",
          "type": "u32"
        },
        {
          "name": "exitFeeNum",
          "type": "u32"
        },
        {
          "name": "exitFeeDenom",
          "type": "u32"
        },
        {
          "name": "ownerFeeNum",
          "type": "u32"
        },
        {
          "name": "ownerFeeDenom",
          "type": "u32"
        }
      ]
    },
    {
      "name": "addPoolToken",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "userTokenAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "poolTokenAccBump",
          "type": "u8"
        },
        {
          "name": "weightNum",
          "type": "u32"
        },
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "finalizePool",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lpMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authorityLpMintAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "lpMintBump",
          "type": "u8"
        },
        {
          "name": "authorityLpMintAccBump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "depositPool",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authorityLpMintAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lpMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenA",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenB",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenC",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenD",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenE",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenA",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenB",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenC",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenD",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenE",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "lpMintBump",
          "type": "u8"
        },
        {
          "name": "authorityLpMintAccBump",
          "type": "u8"
        },
        {
          "name": "maxTokenAmountsIn",
          "type": {
            "vec": "u64"
          }
        },
        {
          "name": "lpAmountOut",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawPool",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authorityLpMintAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lpMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenA",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenB",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenC",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenD",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenE",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenA",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenB",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenC",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenD",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenE",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "lpMintBump",
          "type": "u8"
        },
        {
          "name": "authorityLpMintAccBump",
          "type": "u8"
        },
        {
          "name": "minTokenAmountsOut",
          "type": {
            "vec": "u64"
          }
        },
        {
          "name": "lpAmountIn",
          "type": "u64"
        },
        {
          "name": "numMints",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createTestTokens",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "testTokensAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "btc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "eth",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "slnd",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srm",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "testBump",
          "type": "u8"
        },
        {
          "name": "usdcBump",
          "type": "u8"
        },
        {
          "name": "btcBump",
          "type": "u8"
        },
        {
          "name": "ethBump",
          "type": "u8"
        },
        {
          "name": "slndBump",
          "type": "u8"
        },
        {
          "name": "srmBump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "mintTestTokens",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "testTokensAcc",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "usdc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "btc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "eth",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "slnd",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srm",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "btcAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ethAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "slndAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srmAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "testBump",
          "type": "u8"
        },
        {
          "name": "usdcBump",
          "type": "u8"
        },
        {
          "name": "btcBump",
          "type": "u8"
        },
        {
          "name": "ethBump",
          "type": "u8"
        },
        {
          "name": "slndBump",
          "type": "u8"
        },
        {
          "name": "srmBump",
          "type": "u8"
        },
        {
          "name": "usdcAccBump",
          "type": "u8"
        },
        {
          "name": "btcAccBump",
          "type": "u8"
        },
        {
          "name": "ethAccBump",
          "type": "u8"
        },
        {
          "name": "slndAccBump",
          "type": "u8"
        },
        {
          "name": "srmAccBump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "swapTokenIn",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenAccIn",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenAccIn",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMintOut",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "userTokenAccOut",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenAccOut",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "userTokenAccOutBump",
          "type": "u8"
        },
        {
          "name": "tokenAmountIn",
          "type": "u64"
        },
        {
          "name": "minTokenAmountOut",
          "type": "u64"
        },
        {
          "name": "maxSpotPriceNum",
          "type": "u64"
        },
        {
          "name": "maxSpotPriceDenom",
          "type": "u64"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "testTokenAccount",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "mPoolAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "version",
            "type": "u32"
          },
          {
            "name": "id",
            "type": "publicKey"
          },
          {
            "name": "lpMint",
            "type": "publicKey"
          },
          {
            "name": "lpBalance",
            "type": "u64"
          },
          {
            "name": "controller",
            "type": "publicKey"
          },
          {
            "name": "isFinalized",
            "type": "u32"
          },
          {
            "name": "createdAt",
            "type": "i64"
          },
          {
            "name": "numMints",
            "type": "u32"
          },
          {
            "name": "mints",
            "type": {
              "array": [
                "publicKey",
                5
              ]
            }
          },
          {
            "name": "tokenAccounts",
            "type": {
              "array": [
                "publicKey",
                5
              ]
            }
          },
          {
            "name": "weightsNum",
            "type": {
              "array": [
                "u32",
                5
              ]
            }
          },
          {
            "name": "weightsDenom",
            "type": {
              "array": [
                "u32",
                5
              ]
            }
          },
          {
            "name": "balances",
            "type": {
              "array": [
                "u64",
                5
              ]
            }
          },
          {
            "name": "swapFeeNum",
            "type": "u32"
          },
          {
            "name": "swapFeeDenom",
            "type": "u32"
          },
          {
            "name": "exitFeeNum",
            "type": "u32"
          },
          {
            "name": "exitFeeDenom",
            "type": "u32"
          },
          {
            "name": "ownerFeeNum",
            "type": "u32"
          },
          {
            "name": "ownerFeeDenom",
            "type": "u32"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidArgument",
      "msg": "invalid arguments"
    },
    {
      "code": 6001,
      "name": "InvalidSeed",
      "msg": "invalid seed"
    },
    {
      "code": 6002,
      "name": "InvalidState",
      "msg": "invalid state"
    },
    {
      "code": 6003,
      "name": "Unauthorized",
      "msg": "unauthorized"
    }
  ]
};

export const IDL: Millionfi = {
  "version": "0.1.0",
  "name": "millionfi",
  "instructions": [
    {
      "name": "createPool",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lpMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "lpMintBump",
          "type": "u8"
        },
        {
          "name": "numMints",
          "type": "u8"
        },
        {
          "name": "swapFeeNum",
          "type": "u32"
        },
        {
          "name": "swapFeeDenom",
          "type": "u32"
        },
        {
          "name": "exitFeeNum",
          "type": "u32"
        },
        {
          "name": "exitFeeDenom",
          "type": "u32"
        },
        {
          "name": "ownerFeeNum",
          "type": "u32"
        },
        {
          "name": "ownerFeeDenom",
          "type": "u32"
        }
      ]
    },
    {
      "name": "addPoolToken",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "userTokenAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "poolTokenAccBump",
          "type": "u8"
        },
        {
          "name": "weightNum",
          "type": "u32"
        },
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "finalizePool",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lpMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authorityLpMintAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "lpMintBump",
          "type": "u8"
        },
        {
          "name": "authorityLpMintAccBump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "depositPool",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authorityLpMintAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lpMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenA",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenB",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenC",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenD",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenE",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenA",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenB",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenC",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenD",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenE",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "lpMintBump",
          "type": "u8"
        },
        {
          "name": "authorityLpMintAccBump",
          "type": "u8"
        },
        {
          "name": "maxTokenAmountsIn",
          "type": {
            "vec": "u64"
          }
        },
        {
          "name": "lpAmountOut",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawPool",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authorityLpMintAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "lpMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenA",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenB",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenC",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenD",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenE",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenA",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenB",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenC",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenD",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenE",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "lpMintBump",
          "type": "u8"
        },
        {
          "name": "authorityLpMintAccBump",
          "type": "u8"
        },
        {
          "name": "minTokenAmountsOut",
          "type": {
            "vec": "u64"
          }
        },
        {
          "name": "lpAmountIn",
          "type": "u64"
        },
        {
          "name": "numMints",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createTestTokens",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "testTokensAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "btc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "eth",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "slnd",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srm",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "testBump",
          "type": "u8"
        },
        {
          "name": "usdcBump",
          "type": "u8"
        },
        {
          "name": "btcBump",
          "type": "u8"
        },
        {
          "name": "ethBump",
          "type": "u8"
        },
        {
          "name": "slndBump",
          "type": "u8"
        },
        {
          "name": "srmBump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "mintTestTokens",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "testTokensAcc",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "usdc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "btc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "eth",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "slnd",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srm",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "usdcAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "btcAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ethAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "slndAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srmAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "testBump",
          "type": "u8"
        },
        {
          "name": "usdcBump",
          "type": "u8"
        },
        {
          "name": "btcBump",
          "type": "u8"
        },
        {
          "name": "ethBump",
          "type": "u8"
        },
        {
          "name": "slndBump",
          "type": "u8"
        },
        {
          "name": "srmBump",
          "type": "u8"
        },
        {
          "name": "usdcAccBump",
          "type": "u8"
        },
        {
          "name": "btcAccBump",
          "type": "u8"
        },
        {
          "name": "ethAccBump",
          "type": "u8"
        },
        {
          "name": "slndAccBump",
          "type": "u8"
        },
        {
          "name": "srmAccBump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "swapTokenIn",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mpoolAcc",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenAccIn",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenAccIn",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMintOut",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "userTokenAccOut",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolTokenAccOut",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "poolSeed",
          "type": "publicKey"
        },
        {
          "name": "poolBump",
          "type": "u8"
        },
        {
          "name": "userTokenAccOutBump",
          "type": "u8"
        },
        {
          "name": "tokenAmountIn",
          "type": "u64"
        },
        {
          "name": "minTokenAmountOut",
          "type": "u64"
        },
        {
          "name": "maxSpotPriceNum",
          "type": "u64"
        },
        {
          "name": "maxSpotPriceDenom",
          "type": "u64"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "testTokenAccount",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "mPoolAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "version",
            "type": "u32"
          },
          {
            "name": "id",
            "type": "publicKey"
          },
          {
            "name": "lpMint",
            "type": "publicKey"
          },
          {
            "name": "lpBalance",
            "type": "u64"
          },
          {
            "name": "controller",
            "type": "publicKey"
          },
          {
            "name": "isFinalized",
            "type": "u32"
          },
          {
            "name": "createdAt",
            "type": "i64"
          },
          {
            "name": "numMints",
            "type": "u32"
          },
          {
            "name": "mints",
            "type": {
              "array": [
                "publicKey",
                5
              ]
            }
          },
          {
            "name": "tokenAccounts",
            "type": {
              "array": [
                "publicKey",
                5
              ]
            }
          },
          {
            "name": "weightsNum",
            "type": {
              "array": [
                "u32",
                5
              ]
            }
          },
          {
            "name": "weightsDenom",
            "type": {
              "array": [
                "u32",
                5
              ]
            }
          },
          {
            "name": "balances",
            "type": {
              "array": [
                "u64",
                5
              ]
            }
          },
          {
            "name": "swapFeeNum",
            "type": "u32"
          },
          {
            "name": "swapFeeDenom",
            "type": "u32"
          },
          {
            "name": "exitFeeNum",
            "type": "u32"
          },
          {
            "name": "exitFeeDenom",
            "type": "u32"
          },
          {
            "name": "ownerFeeNum",
            "type": "u32"
          },
          {
            "name": "ownerFeeDenom",
            "type": "u32"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidArgument",
      "msg": "invalid arguments"
    },
    {
      "code": 6001,
      "name": "InvalidSeed",
      "msg": "invalid seed"
    },
    {
      "code": 6002,
      "name": "InvalidState",
      "msg": "invalid state"
    },
    {
      "code": 6003,
      "name": "Unauthorized",
      "msg": "unauthorized"
    }
  ]
};
