import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, InputNumber, Row, Slider, Space, Table} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {TokenDisplay} from "../TokenDisplay";
import {useUserAccounts} from "../../hooks";
import {DEVNET_MINTS_TO_MAIN, ONE_PERCENT_BASIS} from "../../constants/finance";
import {
    displayU64TokenAmount,
    formatNumber,
    getTokenBalance,
    numberToU64TokenAmount
} from "../../utils/utils";
import {useAnchorWallet, useWallet} from "@solana/wallet-adapter-react";
import {depositAll, withdrawAll} from "../../actions/millionfi";
import {useConnection} from "../../contexts/connection";
import {useHistory} from "react-router-dom";
import {PoolAccount} from "../../models";
import {cloneDeep} from "lodash";
import BN from "bn.js";
import {notify} from "../../utils/notifications";

export interface WithdrawTokenRow {
    key: number;
    mint: string;
    weight: number;
    amount: number;
}

export interface WithdrawPoolProps {
    pool: PoolAccount;
    setPool: Dispatch<SetStateAction<PoolAccount | undefined>>;
    userValueDollars: number;
    onSubmit: () => void;
}

export const WithdrawPool = (props: WithdrawPoolProps) => {
    const connection = useConnection();
    const anchorWallet = useAnchorWallet();
    const { wallet } = useWallet();
    const { connected, connect, select } = useWallet();
    const { pool, setPool, userValueDollars, onSubmit } = props;
    const { userAccounts } = useUserAccounts();

    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState<"info" | "success" | "warning" | "error" | undefined>("info");
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const history = useHistory();

    const [withdrawTokenRows, setWithdrawTokenRows] = useState<WithdrawTokenRow[]>([]);
    const [share, setShare] = useState(0);

    useEffect(() => {
        if (!pool) {
            return;
        }
        let rows = [];

        for (let i = 0; i < pool.info.numMints; i++) {
            const mint = pool.info.mints[i];
            const weight = pool.info.weightsNum[i];

            const mainMint = DEVNET_MINTS_TO_MAIN.get(mint.toBase58());

            const o = {
                key: i,
                mint: mainMint || mint.toBase58(),
                weight,
                amount: pool.withdrawAmounts ? parseFloat(displayU64TokenAmount(pool.withdrawAmounts[i])) : 0,
            };

            rows.push(o);
        }

        setWithdrawTokenRows(rows);
    }, [pool]);


    const errorMessage = useMemo(() => {
        return message === "" ? <></>:
            <Alert
                message={message}
                type={messageType}
                style={{margin: "auto", marginTop: 10}}
                showIcon />;
    }, [message, messageType]);

    const goBack = () => {
        history.push('/');
    }

    // Type in one input to determine token amounts for all tokens automatically (deposit all tokens evenly)
    const setTokenAmount = useCallback((key: number, amount: number) => {
        if (amount === null) {
            return;
        }

        let newPool: PoolAccount = cloneDeep(pool);
        newPool.depositAmounts[key] = numberToU64TokenAmount(amount);

        // Find ratio to use to get other token amounts
        const currTokenAmount = numberToU64TokenAmount(amount);
        const currTokenTotal = newPool.info.balances[key];

        for (let i = 0; i < newPool.info.numMints; ++i) {
            newPool.depositAmounts[i] = newPool.info.balances[i].mul(currTokenAmount).div(currTokenTotal);
        }

        newPool.lpRequested = newPool.info.lpBalance.mul(currTokenAmount).div(currTokenTotal);

        setPool(newPool);
    }, [pool, setPool]);

    // Determine any error messages
    useEffect(() => {
        if (!connected) {
            setMessage("Sign in to your wallet to proceed.");
            setMessageType("info");
            return;
        }

        // Check balance of LP tokens
        const lpMint = pool.info.lpMint;
        const isLPBalanceZero = getTokenBalance(lpMint.toBase58(), userAccounts) === 0;
        const isShareZero = share === 0;
        const willClosePool = pool.lpWithdrawn.eq(pool.info.lpBalance);

        if (isLPBalanceZero) {
            setMessage("You don't have any pool tokens to exchange.");
            setMessageType("error");
        } else if (isShareZero) {
            setMessage("Share to withdraw cannot be zero.");
            setMessageType("error");
        } else if (willClosePool) {
            setMessage("Withdrawing the whole pool will close it.");
            setMessageType("warning");
        } else {
            setMessage("");
            setMessageType("info");
        }
    }, [connected, pool, share, userAccounts]);


    const columns = useMemo(() => [
        {
            title: 'Token',
            dataIndex: 'mint',
            key: 'token',
            render: (mint: string, obj: any) => {
                const weight = obj["weight"] / ONE_PERCENT_BASIS;
                return <TokenDisplay weight={weight} mintAddress={mint} showBalance={false}/>
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number, obj: any) => {
                // const lpMint = pool.info.lpMint;
                // const userLpTokenBalance = getTokenBalance(lpMint.toBase58(), userAccounts);
                // const poolTokenBalance = pool.info.balances[obj["key"]];
                //
                // // tokens you can withdraw = total tokens in pool * (user LP tokens / total LP tokens)
                // const tokensEntitledByLp = poolTokenBalance.mul(numberToU64TokenAmount(userLpTokenBalance)).div(pool.info.lpBalance);
                // const shareOfEntitledTokens = parseFloat(displayU64TokenAmount(tokensEntitledByLp.mul(new BN(share)).div(new BN(100))));


                return (
                    <>
                        <InputNumber
                            bordered={false}
                            value={parseFloat(displayU64TokenAmount(pool.withdrawAmounts[obj["key"]]))}
                            size={"large"}
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            style={{display: "block", width: "100%", fontFamily: "Readex Pro Medium"}}
                            disabled
                        />
                    </>
                );
            },
        },
    ], [pool]);

    const handleWithdraw = useCallback(async () => {
        if (anchorWallet && wallet) {
            setButtonEnabled(false);
            try {
                await withdrawAll(connection, anchorWallet, wallet.adapter, pool, userAccounts);
                onSubmit();
            } catch (e) {
                notify({ message: "Failed transaction.", type: "error" });
            }
            setButtonEnabled(true);
        }
    }, [anchorWallet, wallet, connection, pool, userAccounts, onSubmit]);

    const onShareChange = useCallback((num: number) => {
        const lpMint = pool.info.lpMint;
        const userLpTokenBalance = getTokenBalance(lpMint.toBase58(), userAccounts);

        let newPool = cloneDeep(pool);

        for (let i = 0; i < pool.info.numMints; i++) {
            const poolTokenBalance = pool.info.balances[i];
            const tokensEntitledByLp = poolTokenBalance.mul(numberToU64TokenAmount(userLpTokenBalance)).div(pool.info.lpBalance);
            const shareOfEntitledTokens = tokensEntitledByLp.mul(new BN(num)).div(new BN(100));
            newPool.withdrawAmounts[i] = shareOfEntitledTokens;
        }

        newPool.lpWithdrawn = numberToU64TokenAmount(userLpTokenBalance).mul(new BN(num)).div(new BN(100));

        setShare(num);
        setPool(newPool);
    }, [pool, userAccounts, setPool]);

    const panel = (
        <div>
            <LeftOutlined
                className={"arrow-select"}
                style={{marginRight: 5, verticalAlign: "text-top", fontWeight: "Readex Pro Bold"}}
                onClick={goBack}
            />
            <h1 style={{display: "inline"}}>Withdraw Liquidity</h1>

            <div style={{marginTop: 20, marginLeft: 15, marginRight: 15, padding: 10, border: "solid 2px #1C1C1C", borderRadius: 20}}>
                <Row style={{marginTop: 5}}>
                    <Col span={24}>
                        <h3 style={{display: "inline", marginRight: 5}}>Share to withdraw: </h3>
                        <InputNumber
                            onFocus={(e) => e.target.select()}
                            formatter={(num, info) => info.userTyping ? (num || 0).toString() : num + "%"}
                            maxLength={3}
                            controls={false}
                            defaultValue={100}
                            precision={0}
                            value={share}
                            size={"large"}
                            min={0}
                            max={100}
                            step={1}
                            style={{fontFamily: "Readex Pro Medium", width: 70, backgroundColor: "none"}}
                            onChange={onShareChange}
                        />
                    </Col>
                </Row>

                <Row style={{marginTop: 10}}>
                    <Col span={24}>
                        <Slider
                            value={share}
                            style={{marginLeft: 20, marginRight: 20}}
                            min={0}
                            max={100}
                            onChange={onShareChange}
                            tipFormatter={null}
                        />
                    </Col>
                </Row>
            </div>

            <h3 style={{marginTop: 15}}>You will receive ${formatNumber.format(userValueDollars * (share / 100))}:
            </h3>
            <Table columns={columns} dataSource={withdrawTokenRows} size={"large"} pagination={false}/>

            <Row gutter={[16, 16]}>
                {errorMessage}
                <Col span={24}>
                    <Button
                        disabled={!connected || (!!message && messageType === "error") || !buttonEnabled}
                        className={"continue-button-gradient"}
                        size="large"
                        onClick={handleWithdraw}
                    >
                        Withdraw
                    </Button>
                </Col>
            </Row>
        </div>
    );

    return panel;
};
